import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { RaffleChannel } from "../../../../@types/raffle";
import Confetti from "react-dom-confetti";
import StarOutlineIcon from "@mui/icons-material/StarOutline"; // Example icon
import {
  manualFetchedTicketData,
  Past3DaysWinnersResponse,
  raffleSettingsData,
  selectedWinnersData,
} from "../../../../selectors/raffleSelector";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import FullPageLoader from "../../../../components/full-loader";
import StickyPaneRightSection from "./components/sticky-pane";
import { RaffleRequestPayload } from "../../../../@types/selection-model";
import NumberListSection from "./components/number-list";
import {
  fetchDynamicTickets,
  fetchPast3DaysRaffleWinners,
  selectRaffleWinner,
} from "../../../../store/thunks/raffle-thunk";
import { AppDispatch } from "../../../../store";
import logging from "../../../../logging";
import CustomSnackbar from "../../../../components/custom-snackbar.tsx";
import RiseLoader from "react-spinners/RiseLoader";
import { PacmanLoader, RingLoader } from "react-spinners";
import { formatPhoneNumber } from "../../../../utils";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { staffSelectorData } from "../../../../selectors/staffSelector";
import { StaffModel } from "../../../../@types/staff-model";

const confettiConfig = {
  angle: 270,
  spread: 360,
  startVelocity: 70,
  elementCount: 100,
  dragFriction: 0.12,
  duration: 5000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

// Styled components using MUI
const Wrapper = styled("div")(() => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  background: "linear-gradient(135deg, #003366, black)", // Dark blue gradient
  boxSizing: "border-box",
}));

const ShufflingPot = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(3),
}));

const TopBar = styled("div")(({ theme }) => ({
  minWidth: "250px",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  marginBottom: 20,
}));

const LotteryDisplay: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const confettiRef = useRef(null);
  // const [currentValue, setCurrentValue] = useState<string>("000000000000"); // Initial value
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const raffleSettings = useSelector(raffleSettingsData); // Provide a default object
  const past3DaysWinners = useSelector(Past3DaysWinnersResponse);
  const manualTickets = useSelector(manualFetchedTicketData) || [];
  const winners = useSelector(selectedWinnersData) || [];
  const partners = useSelector(staffSelectorData);
  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);

  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const today = dayjs().startOf("day");

  const [selectedChannel, setSelectedChannel] = useState<RaffleChannel | null>(
    null,
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success",
  );
  const [selectedPartner, setSelectedPartner] = useState<string>("All");

  const handleChannelChange = (event: SelectChangeEvent<string>) => {
    // Find the channel with the matching ID
    const newChannel = raffleSettings!.raffleChannels.find(
      (channel) => channel.channelID === event.target.value,
    );
    if (newChannel) {
      setSelectedChannel(newChannel);
    }
  };

  const filteredPartners = useMemo(() => {
    return partners.filter((partner: StaffModel) => {
      const role = roles.find((r) => r.roleID === partner.roleID);
      return (
        role &&
        (role.roleName === "ReadOnlyPartner" || role.roleName === "RafflePlay")
      );
    });
  }, [partners, roles]);

  const todaysWinners = useMemo(() => {
    return past3DaysWinners.filter((winner) => {
      const winnerDate = dayjs(winner.drawDate).startOf("day");
      return today.isSame(winnerDate);
    });
  }, [past3DaysWinners]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Automatically select the partner if the user is a partner
    if (user && roles) {
      const userRole = roles.find((role) => role.roleID === user.roleID);
      if (userRole?.roleName.includes("Admin")) {
        setSelectedPartner("All"); // Admins can view all or select a partner
      } else if (
        userRole?.roleName === "ReadOnlyPartner" ||
        userRole?.roleName === "RafflePlay"
      ) {
        setSelectedPartner(user.staffID); // Automatically select partner
      }
    }
  }, [user, roles]);

  const theme = useTheme();
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString(); // Converts date to ISO string with full precision and 'Z' timezone
  };

  useEffect(() => {
    if (
      raffleSettings &&
      raffleSettings.raffleChannels &&
      raffleSettings.raffleChannels.length > 0
    ) {
      setSelectedChannel(raffleSettings.raffleChannels[0]);
    } else {
      setSelectedChannel(null);
    }
  }, [raffleSettings]);

  const handleFetchWinner = async () => {
    setIsAnimating(true);

    if (
      !selectedChannel ||
      !startTime ||
      !endTime ||
      manualTickets.length == 0
    ) {
      setSnackbarMessage("Please ensure all fields are filled.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
      return;
    }
    const payload: RaffleRequestPayload = {
      channelID: selectedChannel.channelID,
      startTime: formatDate(startTime),
      endTime: formatDate(endTime),
    };
    setShowConfirmModal(false);

    try {
      const resultAction = await dispatch(selectRaffleWinner(payload));
      if (selectRaffleWinner.fulfilled.match(resultAction)) {
        // Assuming the winner data is directly usable and available in the payload
        const winnerData = resultAction.payload;
        console.log("winners from backend", winnerData);
        // Trigger the animation with the winner data
        if (!winnerData || winnerData.length === 0) {
          console.error("No winners data available for animation.");
          setSnackbarMessage(
            "No winners data available. Please check the selection.",
          );
          setAlertSeverity("error");
          setOpenSnackbar(true);
        }

        setTimeout(handleAnimationComplete, 2000); // Allow some time before finalizing the animation
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);

        setSnackbarMessage("You not allowed to select winner, try again later");
        setAlertSeverity("error");
        setOpenSnackbar(true);
        setIsAnimating(false);
      } else {
        logging.error(resultAction.error);
        setSnackbarMessage("You not allowed to select winner, try again later");
        setAlertSeverity("error");
        setOpenSnackbar(true);
        setIsAnimating(false);
      }
    } catch (error) {
      setSnackbarMessage("Oops!!!. Something bad happened, try again later");
      setAlertSeverity("error");
      setOpenSnackbar(true);
      setIsAnimating(false);
      logging.error("Dispatch failed:", (error as Error).message);
    }
  };
  const handleAnimationComplete = async () => {
    await dispatch(fetchPast3DaysRaffleWinners());
    setIsAnimating(false);
    setShowModal(true);
  };

  const handleStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(event.target.value);
  };

  const handleFetch = async () => {
    if (!selectedChannel || !startTime || !endTime) return;
    const payload: RaffleRequestPayload = {
      channelID: selectedChannel.channelID,
      startTime: formatDate(startTime),
      endTime: formatDate(endTime),
    };
    try {
      const action = fetchDynamicTickets(payload);
      const resultAction = await dispatch(action);

      // Check if it's an error message/object based on some criterion
      if (typeof resultAction.payload === "string") {
        logging.error(resultAction.payload);
      } else if (fetchDynamicTickets.fulfilled.match(resultAction)) {
        logging.info("fetched successfully!");
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    }
  };

  const ConfirmationModal = () => (
    <Modal
      open={showConfirmModal}
      onClose={() => setShowConfirmModal(false)}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography id="confirmation-modal-title" variant="h6" component="h2">
          Confirm Raffle Draw
        </Typography>
        <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to start the raffle draw for the selected
          channel and timeframe?
        </Typography>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchWinner}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const handleStartClick = () => {
    if (manualTickets.length === 0) {
      // Optionally handle the case where no tickets are available
      console.log("No tickets available for the draw.");
      return;
    }
    setShowConfirmModal(true);
  };

  const handlePartnerChange = (event: SelectChangeEvent<string>) => {
    setSelectedPartner(event.target.value);
  };

  if (
    !partners ||
    !user ||
    !raffleSettings ||
    !raffleSettings.raffleChannels ||
    raffleSettings.raffleChannels.length === 0
  ) {
    return <FullPageLoader />;
  }

  const filteredChannels = useMemo(() => {
    if (selectedPartner === "All") {
      return [];
    }
    const partner = filteredPartners.find((p) => p.staffID === selectedPartner);
    return raffleSettings.raffleChannels.filter(
      (channel) => channel.businessID === partner?.businessID,
    );
  }, [raffleSettings.raffleChannels, selectedPartner]);

  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };

  const roleName =
    getRoleData(user?.roleID)?.roleName || "ReadOnlyPartner" || "RafflePlay";

  return (
    // <FullScreen handle={fullScreenHandle}>
    <>
      <CustomSnackbar
        message={snackbarMessage}
        severity={alertSeverity}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
      />
      {!isDesktop && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            backgroundColor: "black", // Changed from rgba to solid color
            zIndex: 2000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            padding: "20px",
            textAlign: "center",
          }}
        >
          This draws is only available on desktop.
        </div>
      )}

      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Confetti
          active={showModal}
          config={confettiConfig}
          ref={confettiRef}
        />
      </div>
      <Wrapper>
        {ConfirmationModal()}

        <NumberListSection tickets={manualTickets} />
        <ShufflingPot>
          {(roleName === "Super Admin" || roleName === "Admin") && (
            <TopBar>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="partner-select-label">
                    Select Partner
                  </InputLabel>
                  <Select
                    labelId="partner-select-label"
                    id="partner-select"
                    value={selectedPartner}
                    onChange={handlePartnerChange}
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {filteredPartners.map((partner) => (
                      <MenuItem key={partner.staffID} value={partner.staffID}>
                        {`${partner.firstName} ${partner.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </TopBar>
          )}

          <Button
            variant="contained"
            color="secondary"
            onClick={handleStartClick}
            disabled={
              isAnimating ||
              manualTickets.length == 0 ||
              !startTime ||
              !endTime ||
              !selectedChannel?.channelID
            }
            sx={{ mb: 5, pr: 5, pl: 5, mt: 8 }}
          >
            {isAnimating ? "Drawing Numbers..." : "Start Draw"}
          </Button>
          <Box>{isAnimating && <PacmanLoader color="white" size={100} />}</Box>

          {/* <SlotBox>
            {currentValue.split("").map((char, index) => (
              <DigitBox key={index}>
                <SlotCounter
                  value={char}
                  sequentialAnimationMode
                  useMonospaceWidth
                  speed={10}
                  duration={2} // Animation speed per character
                  animateOnVisible={false} // Ensures continuous control
                />
              </DigitBox>
            ))}
          </SlotBox> */}
        </ShufflingPot>

        {winners && showModal && (
          <Modal open={showModal} onClose={() => setShowModal(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: theme.palette.background.default,
                p: 4,
                border: `2px solid #ff79b0`,
                boxShadow: theme.shadows[5],
                borderRadius: theme.shape.borderRadius,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                gap: 2,
              }}
            >
              <StarOutlineIcon
                sx={{
                  fontSize: 40,
                  color: "#ff79b0",
                }}
              />
              <Typography variant="h5" color="#ff4081" gutterBottom>
                🎉 Congratulations! 🎉
              </Typography>
              <Typography variant="h6">
                Winning Number:{" "}
                <b>
                  {winners.map((winner) =>
                    formatPhoneNumber(winner.winnerPhoneNumber),
                  )}
                </b>
              </Typography>
              <Typography>
                You have been selected as the lucky winner in our raffle draw!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowModal(false)}
                sx={{ mt: 2 }}
              >
                Close
              </Button>
            </Box>
          </Modal>
        )}
        {/* Right Sticky Pane */}
        <StickyPaneRightSection
          channelSelected={selectedChannel}
          todaysWinners={todaysWinners}
          selectedChannel={selectedChannel?.channelID ?? ""}
          onChannelChange={handleChannelChange}
          onStartTimeChange={handleStartTimeChange}
          onEndTimeChange={handleEndTimeChange}
          startTime={startTime}
          endTime={endTime}
          onSubmit={handleFetch}
          channels={filteredChannels}
        />
      </Wrapper>
    </>
    // </FullScreen>
  );
};

export default LotteryDisplay;
