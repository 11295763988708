import { BankFilled, GiftFilled } from "@ant-design/icons";
import React from "react";

import {
  People,
  Dashboard as DashboardIcon,
  Category,
  Ballot,
  Key,
  SwitchAccessShortcut,
  SwitchAccessShortcutAdd,
  AccountBalanceWallet,
  AccountBalance,
  Receipt,
  Settings,
  PersonAdd,
  GroupAdd,
  GifBox,
  AddBox,
  Redeem,
  MilitaryTech,
  AssignmentReturn,
  SdCardAlert,
  Celebration,
  BookOnline,
  MoveUp,
  AdsClick,
  SettingsInputHdmi,
  Message,
  Person,
  CelebrationOutlined,
  UnfoldLessDoubleOutlined,
} from "@mui/icons-material";

import { MenuProps } from "antd";

type MenuItem = {
  key: string;
  icon: React.ReactNode;
  label: string;
  roles?: string[];
  children?: MenuItem[];
};

export const filterMenuItems = (
  menuItems: MenuItem[],
  roleName: string | undefined,
): MenuProps["items"] => {
  if (!roleName) return []; // Return an empty array if roleName is undefined

  return menuItems
    .filter((item) => !item.roles || item.roles.includes(roleName)) // Filter items by roles if specified
    .map((item) => {
      const { roles, ...cleanedItem } = item; // Exclude roles from the returned item
      return {
        ...cleanedItem,
        key: String(item.key), // Ensure key is a string
        label: item.label, // Assign the label
        icon: item.icon, // Pass the icon if available
        children: item.children
          ? filterMenuItems(item.children, roleName) // Recursively filter children
          : undefined,
      };
    });
};

export const menuConfig = [
  {
    key: "/",
    icon: <DashboardIcon />,
    label: "Dashboard",
    roles: [
      "Super Admin",
      "Admin",
      "Basic User",
      "ReadOnlyPartner",
      "RafflePlay",
    ],
  },
  {
    key: "/spin-dashboard",
    icon: <GiftFilled />,
    label: "Spin Dashboard",
    roles: ["Super Admin"],
  },
  {
    key: "/users",
    icon: <People />,
    label: "Customers",
    roles: ["Super Admin"],
  },
  {
    key: "/employees",
    icon: <People />,
    label: "Employees",
    roles: ["Super Admin"],
    children: [
      {
        key: "/register-staff",
        icon: <PersonAdd />,
        label: "Register Staff",
        roles: ["Super Admin"],
      },
      {
        key: "/staffs",
        icon: <GroupAdd />,
        label: "Staff List",
        roles: ["Super Admin"],
      },
    ],
  },
  {
    key: "/access-roles",
    icon: <Key />,
    label: "Access Roles",
    roles: ["Super Admin"],
    children: [
      {
        key: "/add-role",
        icon: <SwitchAccessShortcutAdd />,
        label: "Add Role",
        roles: ["Super Admin"],
      },
      {
        key: "/roles-list",
        icon: <SwitchAccessShortcut />,
        label: "Roles List",
        roles: ["Super Admin"],
      },
    ],
  },
  {
    key: "/category",
    icon: <Category />,
    label: "Category",
    roles: ["Super Admin"],
    children: [
      {
        key: "/products",
        icon: <Ballot />,
        label: "Product List",
        roles: ["Super Admin"],
      },
      {
        key: "/add-product",
        icon: <AddBox />,
        label: "Add Product",
        roles: ["Super Admin"],
      },
    ],
  },
  {
    key: "/boxes",
    icon: <Redeem />,
    label: "Boxes",
    roles: ["Super Admin"],
    children: [
      {
        key: "/box-list",
        icon: <GifBox />,
        label: "Box List",
        roles: ["Super Admin"],
      },
      {
        key: "/add-box",
        icon: <AddBox />,
        label: "Add Box",
        roles: ["Super Admin"],
      },
    ],
  },
  {
    key: "/wallets",
    icon: <AccountBalanceWallet />,
    label: "Wallets",
    roles: ["Super Admin"],
  },
  {
    key: "/transactions",
    icon: <AccountBalance />,
    label: "Transactions",
    roles: ["Super Admin"],
    children: [
      {
        key: "/deposit",
        icon: <Receipt />,
        label: "Deposit",
        roles: ["Super Admin", "Admin"],
      },
      {
        key: "/buy-back",
        icon: <AssignmentReturn />,
        label: "Buy Back",
        roles: ["Super Admin", "Admin"],
      },
    ],
  },
  {
    key: "/raffles",
    icon: <AdsClick />,
    label: "Raffle Info",
    roles: ["Super Admin", "Admin", "ReadOnlyPartner", "RafflePlay"],
    children: [
      {
        key: "/raffle-winners",
        icon: <Celebration />,
        label: "Raffle Winners",
        roles: ["Super Admin", "Admin", "ReadOnlyPartner", "RafflePlay"],
      },
      {
        key: "/raffle-tickets",
        icon: <BookOnline />,
        label: "Raffle Tickets",
        roles: ["Super Admin", "Admin", "ReadOnlyPartner"],
      },
      {
        key: "/raffle-transactions",
        icon: <MoveUp />,
        label: "Raffle Payment",
        roles: ["Super Admin", "Admin"],
      },
      {
        key: "/raffle-settings",
        icon: <Settings />,
        label: "Raffle Settings",
        roles: ["Super Admin"],
      },
      {
        key: "/send-users-messages",
        icon: <Message />,
        label: "Send Users Messages",
        roles: ["Super Admin"],
      },
      {
        key: "/sms-ussd",
        icon: <UnfoldLessDoubleOutlined />,
        label: "Sms-Ussd",
        roles: ["Super Admin"],
      },
    ],
  },
  {
    key: "/raffle-dashboard",
    icon: <Celebration />,
    label: "Raffle Dashboard",
    roles: ["Super Admin"],
  },
  {
    key: "/raffle-payouts",
    icon: <BankFilled />,
    label: "Raffle Payout",
    roles: ["Super Admin"],
  },
  {
    key: "/manual-triggers",
    icon: <SettingsInputHdmi />,
    label: "Manual Trigger",
    roles: ["Super Admin"],
  },
  {
    key: "/settings",
    icon: <Settings />,
    label: "Settings",
    roles: ["Super Admin"],
    children: [
      { key: "/game-settings", icon: <Receipt />, label: "Game Settings" },
    ],
  },
  {
    key: "/winnings",
    icon: <MilitaryTech />,
    label: "Winning",
    roles: ["Super Admin", "Admin"],
  },
  {
    key: "/vtu-purchases",
    icon: <SdCardAlert />,
    label: "VTU Purchase",
    roles: ["Super Admin", "Admin"],
  },
  {
    key: "/account",
    icon: <Person />,
    label: "Account",
    roles: [
      "Super Admin",
      "Admin",
      "Basic User",
      "ReadOnlyPartner",
      "RafflePlay",
    ],
  },
];
