import React, { useState } from "react";
import {
  styled,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Paper,
  useTheme,
  TextField,
  Button,
  Box,
} from "@mui/material";
import {
  RaffleRequestPayload,
  StickyPaneRightProps,
} from "../../../../../@types/selection-model";
import { useNavigate } from "react-router-dom";

const StickyPaneRight = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0, // Align to the right
  overflowY: "auto",
  height: "100%",
  width: "250px",
  background: "white",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  zIndex: 1000,
}));
const FixedSettings = styled("div")(({ theme }) => ({
  background: "rgba(0, 0, 0, 0.8)",
  padding: theme.spacing(2),
  textAlign: "center",
  zIndex: 1000,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const FixedSettingsData = styled("div")(({ theme }) => ({
  background: "white",
  padding: theme.spacing(2),
  textAlign: "center",
  zIndex: 1000,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ScrollableWinners = styled("div")(({ theme }) => ({
  flex: 1,
  overflowY: "auto",
  maxHeight: "calc(100% - 48px)",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.grey[500],
    borderRadius: "8px",
  },
}));
const StickyHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  background: "rgba(0, 0, 0, 0.8)",
  zIndex: 2,
  padding: theme.spacing(2),
}));

const WinnerIcon = styled("span")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: 30,
  height: 30,
  backgroundColor: theme.palette.primary.main, // Use theme primary color
  borderRadius: "50%",
  color: theme.palette.common.white,
  marginRight: theme.spacing(2),
}));

const StickyPaneRightSection: React.FC<StickyPaneRightProps> = ({
  channelSelected,
  todaysWinners,
  selectedChannel,
  startTime,
  endTime,
  channels,
  onChannelChange,
  onStartTimeChange,
  onEndTimeChange,
  onSubmit,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleExit = () => {
    navigate(`/dashboard/raffle-winners`);
  };
  return (
    <StickyPaneRight>
      <FixedSettings>
        <Button
          variant="contained"
          color="error"
          onClick={handleExit}
          fullWidth
        >
          Exit{" "}
        </Button>
        <Button></Button>

        <Typography variant="h6" color="paleturquoise">
          Settings
        </Typography>
        <FixedSettingsData>
          {channels.length > 0 ? (
            <Box>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="channel-select-label">
                  Select Channel
                </InputLabel>
                <Select
                  labelId="channel-select-label"
                  id="channel-select"
                  value={selectedChannel}
                  onChange={onChannelChange}
                  label="Select Channel"
                >
                  {channels.map((channel) => (
                    <MenuItem key={channel.channelID} value={channel.channelID}>
                      {channel.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: 18, mb: 3 }}
              >
                {`Daily Winners: ${channelSelected?.numberOfWinners}`}
              </Typography>

              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: 18, mb: 3 }}
              >
                {`Selected Winners: ${todaysWinners.length}`}
              </Typography>
              <TextField
                label="Start Time"
                type="datetime-local"
                value={startTime}
                onChange={onStartTimeChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <TextField
                label="End Time"
                type="datetime-local"
                value={endTime}
                onChange={onEndTimeChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                fullWidth
                sx={{ mt: 2 }}
              >
                Apply Settings
              </Button>
            </Box>
          ) : (
            <Box>
              <Typography sx={{ textAlign: "center", margin: 2 }}>
                No partners channel selected today.
              </Typography>
            </Box>
          )}
        </FixedSettingsData>
      </FixedSettings>
      <ScrollableWinners>
        <StickyHeader>
          <Typography
            variant="h6"
            color="paleturquoise"
            sx={{ textAlign: "center" }}
          >
            Today's Winners
          </Typography>
        </StickyHeader>
        {/* Scrollable list of winners */}

        {todaysWinners.length > 0 ? (
          todaysWinners.map((winner, index) => (
            <Paper
              sx={{
                padding: theme.spacing(2),
                margin: theme.spacing(1),
                backgroundColor: theme.palette.grey[800], // Darker card background
                color: theme.palette.common.white,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)", // Slight scale on hover
                  boxShadow: theme.shadows[5], // Elevate card on hover
                },
              }}
              key={index}
              elevation={3}
            >
              <WinnerIcon>{/* Icon here, like a star or badge */}🌟</WinnerIcon>
              <Typography variant="body1">
                {winner.winnerPhoneNumber}
              </Typography>
            </Paper>
          ))
        ) : (
          <Typography sx={{ textAlign: "center", margin: 2 }}>
            No winners today.
          </Typography>
        )}
      </ScrollableWinners>
    </StickyPaneRight>
  );
};

export default StickyPaneRightSection;
