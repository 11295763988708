import React, { FC, useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Avatar,
  Rating,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
  FormControlLabel,
  Switch,
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import states from "../../../../utils/states.json";
import citiesData from "../../../../utils/cities.json";
import { formatCurrency } from "../../../../utils";
import { BankDetailModel } from "../../../../@types/wallet";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { UserModel } from "../../../../@types/user-model";
import {
  usersSelectorList,
  usersSelectorLoadingState,
} from "../../../../selectors/userSelector";
import {
  allBanksDetails,
  allWalletsData,
} from "../../../../selectors/walletSelector";
import { WalletInfo } from "../../../../@types/wallet";
import { FileCopy } from "@mui/icons-material";
import { Spin } from "antd";
import { AppDispatch } from "../../../../store";
import {
  getUsers,
  updateCustomerData,
} from "../../../../store/thunks/users-thunks";
import logging from "../../../../logging";

const UserEditForm = () => {
  const { id } = useParams<{ id: string }>();
  const usersData: UserModel[] | null = useSelector(usersSelectorList);
  const allWallets: WalletInfo[] | null = useSelector(allWalletsData);
  const allBanksData = useSelector(allBanksDetails);
  const navigate = useNavigate();
  const isLoading = useSelector(usersSelectorLoadingState);

  const [user, setUser] = useState<UserModel | null>(null);
  const [userWallet, setUserWallet] = useState<WalletInfo | null>(null);
  const [selectedState, setSelectedState] = useState("");
  const [lgas, setLgas] = useState<string[]>([]);
  const [editMode, setEditMode] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const banksOptions = Array.isArray(allBanksData) ? allBanksData : [];

  const [selectedBank, setSelectedBank] = useState<BankDetailModel | null>(
    null,
  );
  const [bankAccountName, setBankAccountName] = useState("");

  const formikRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    // Simulating fetching user data by ID
    if (id && usersData) {
      // Set initial selected bank
      const selectedUser = usersData.find((user) => user.userId === id);
      if (selectedUser) {
        setUser(selectedUser || null);
        setBankAccountName(selectedUser?.bankAccountName);

        const initialBank = banksOptions.find(
          (bank) => bank.bankCode === selectedUser.bankCode,
        );
        setSelectedBank(initialBank || null);

        setSelectedState(selectedUser.state);
        setUserWallet(
          allWallets?.find(
            (wallet: WalletInfo) => wallet.userID === selectedUser.userId,
          ) || null,
        );
        setLgas(
          citiesData.find((city) => city.name === selectedUser.state)?.lgas ||
            [],
        );
      }
    }
  }, [id, usersData]);

  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  const handleSubmit = async (values: UserModel, actions: any) => {
    try {
      const resultAction = await dispatch(updateCustomerData(values));
      if (updateCustomerData.fulfilled.match(resultAction)) {
        await dispatch(getUsers());
        logging.info("user data updated successful!");
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  if (isLoading || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied to clipboard!");
      },
      () => {
        alert("Failed to copy!");
      },
    );
  };

  const initialValues: UserModel = {
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    phoneNumber: user?.phoneNumber,
    isNewsLetterSubscribed: user?.isNewsLetterSubscribed,
    isVerified: user?.isVerified,
    street: user?.street,
    accountNumber: user?.accountNumber,
    bankAccountName: user?.bankAccountName,
    bankCode: user?.bankCode,
    bankName: user?.bankName,
    isBankAccountVerified: user?.isBankAccountVerified,
    state: user?.state,
    city: user?.city,
    country: user?.country,
    postalCode: user?.postalCode,
    userId: user.userId,
    isFirstLogin: user.isFirstLogin,
    agentID: user.agentID,
    walletID: user.walletID,
    lastMessageSent: user.lastMessageSent,
    createdAt: user.createdAt,
    isBvnVerified: user?.isBvnVerified,
    depositBlocked: user?.depositBlocked,
    accountBlocked: user?.accountBlocked,
    withdrawalBlocked: user.withdrawalBlocked,
    bvnNumber: user.bvnNumber,
    isPinCreated: user.isPinCreated,
    recipientCode: user.recipientCode,
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // validationSchema={UserSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Users Details
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    sx={{ marginRight: 2 }}
                    onClick={() => copyToClipboard(user?.userId)}
                  >
                    <FileCopy />
                  </IconButton>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{
                      fontWeight: 600,
                      fontSize: 17,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 140,
                    }}
                  >
                    {user.userId}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Button
                onClick={() => navigate(-1)} // Use history.goBack() to navigate back
                variant="contained"
                color="primary"
              >
                Go Back
              </Button>
              {editMode ? (
                <>
                  <Button
                    sx={{ marginRight: 2 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save Changes
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={exitEditMode}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={enterEditMode}
                >
                  Edit
                </Button>
              )}
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Wallet Infomation
                  </Typography>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Wallet ID:
                      </Typography>
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                      <IconButton
                        sx={{ marginRight: 2 }}
                        onClick={() =>
                          copyToClipboard(userWallet?.walletID || "")
                        }
                      >
                        <FileCopy />
                      </IconButton>
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{
                          fontWeight: 600,
                          fontSize: 17,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 140,
                        }}
                      >
                        {userWallet?.walletID}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        First Deposit:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 17 }}
                      >
                        {userWallet?.isFirstDeposit ? "Yes" : "No"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Main Account:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22 }}
                      >
                        {userWallet
                          ? formatCurrency(userWallet?.mainAccount, true)
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Buy Back Account:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22 }}
                      >
                        {userWallet
                          ? formatCurrency(userWallet?.buyBackAccount, true)
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Bonus Account:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22 }}
                      >
                        {userWallet
                          ? formatCurrency(userWallet?.bonusAccount, true)
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Personal Information */}
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Personal Information
                  </Typography>

                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    margin="normal"
                    inputProps={{ readOnly: true }}
                    value={values.phoneNumber}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.email}
                    error={touched.email && !!errors.email}
                    helperText={
                      touched.email && typeof errors.email === "string"
                        ? errors.email
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.firstName}
                    onChange={handleChange}
                    error={touched.firstName && !!errors.firstName}
                    helperText={
                      touched.firstName && typeof errors.firstName === "string"
                        ? errors.firstName
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.lastName}
                    onChange={handleChange}
                    error={touched.lastName && !!errors.lastName}
                    helperText={
                      touched.lastName && typeof errors.lastName === "string"
                        ? errors.lastName
                        : ""
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Bank Infomation
                  </Typography>
                  {!editMode ? (
                    <Grid item xs={12} mb={2}>
                      <TextField
                        fullWidth
                        id="bankName"
                        name="bankName"
                        label="Bank Name"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={user?.bankName}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} mb={2}>
                      <Autocomplete
                        id="bankName"
                        options={banksOptions}
                        getOptionLabel={(option) => option.bankName}
                        isOptionEqualToValue={(option, value) =>
                          option.bankCode === value.bankCode
                        }
                        onChange={(event, value) => {
                          setSelectedBank({
                            bankName: value ? value.bankName : "",
                            bankCode: value ? value.bankCode : "",
                            bankID: value ? value.bankID : null,
                          });
                          setFieldValue(
                            "bankName",
                            value ? value.bankName : "",
                          );
                          setFieldValue(
                            "bankCode",
                            value ? value.bankCode : "",
                          );
                        }}
                        value={selectedBank}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Bank Name"
                            variant="outlined"
                            fullWidth
                            error={touched.bankName && Boolean(errors.bankName)}
                            helperText={
                              touched.bankName &&
                              typeof errors.bankName === "string"
                                ? errors.bankName
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} mb={2}>
                    <TextField
                      fullWidth
                      id="accountNumber"
                      name="accountNumber"
                      label="Account Number"
                      InputProps={{
                        readOnly: !editMode ? true : false,
                      }}
                      value={values.accountNumber}
                      onChange={(e) => {
                        setFieldValue("accountNumber", e.target.value);
                      }}
                      error={
                        touched.accountNumber && Boolean(errors.accountNumber)
                      }
                      helperText={
                        touched.accountNumber &&
                        typeof errors.accountNumber === "string"
                          ? errors.accountNumber
                          : ""
                      }
                    />
                  </Grid>
                  {user?.recipientCode && (
                    <Grid item xs={12} mb={2}>
                      <TextField
                        fullWidth
                        label="Recipient Code"
                        name="recipientCode"
                        margin="normal"
                        inputProps={{ readOnly: true }}
                        value={values.recipientCode}
                        onChange={handleChange}
                      />
                    </Grid>
                  )}
                  {user?.isBankAccountVerified && (
                    <Grid item xs={12} mb={2}>
                      <TextField
                        fullWidth
                        id="bankAccountName"
                        name="bankAccountName"
                        label="Account Name"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={user.bankAccountName}
                      />
                    </Grid>
                  )}

                  {bankAccountName && !user?.isBankAccountVerified && (
                    <Grid item xs={12} mb={2}>
                      <TextField
                        fullWidth
                        id="bankAccountName"
                        name="bankAccountName"
                        label="Account Name"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={bankAccountName}
                      />
                    </Grid>
                  )}

                  {editMode &&
                    values.accountNumber !== user.accountNumber &&
                    values.bankCode !== user.bankCode && (
                      <Grid
                        item
                        xs={12}
                        justifyContent="flex-end"
                        display="flex"
                        alignItems="center"
                      >
                        <Button
                          color={"primary"}
                          variant={"contained"}
                          type="button"
                          size="large"
                          disabled={!values.accountNumber || !values.bankCode}
                          // startIcon={
                          // 	isVerifying ? <CircularProgress size={20} color="inherit" /> : null
                          // }
                        >
                          {user.isBankAccountVerified ? (
                            <span style={{ alignItems: "center" }}>
                              Verified <CheckIcon sx={{ color: "green" }} />
                            </span>
                          ) : (
                            "Verify"
                          )}
                        </Button>
                      </Grid>
                    )}
                </Paper>
              </Grid>
              {/* Contact Information */}
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Contact Information
                  </Typography>

                  <TextField
                    fullWidth
                    label="Street"
                    name="street"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.street}
                    onChange={handleChange}
                    error={touched.street && !!errors.street}
                    helperText={
                      touched.street && typeof errors.street === "string"
                        ? errors.street
                        : ""
                    }
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="state-select-label">State</InputLabel>
                    <Select
                      labelId="state-select-label"
                      id="state-select"
                      name="state"
                      value={values.state}
                      inputProps={{ readOnly: editMode ? false : true }}
                      label="State"
                      onChange={(e) => {
                        const newState = e.target.value;

                        setFieldValue("state", newState);
                        const newLgas =
                          citiesData.find((city) => city.name === newState)
                            ?.lgas || [];
                        setLgas(newLgas);
                        setFieldValue("lga", "");
                      }}
                    >
                      {states.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="city-select-label">City</InputLabel>
                    <Select
                      labelId="city-select-label"
                      id="city-select"
                      name="city"
                      value={values.city}
                      inputProps={{ readOnly: editMode ? false : true }}
                      label="City"
                      onChange={handleChange}
                      disabled={!lgas.length}
                    >
                      {lgas.map((lga) => (
                        <MenuItem key={lga} value={lga}>
                          {lga}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    margin="normal"
                    inputProps={{ readOnly: true }}
                    value={values.country}
                    error={touched.country && !!errors.country}
                    helperText={
                      touched.country && typeof errors.country === "string"
                        ? errors.country
                        : ""
                    }
                  />
                </Paper>
              </Grid>

              {/* Subscription & Settings */}
              <Grid item xs={12} sm={6} mb={3}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Subscription & Settings
                  </Typography>

                  {/* Row for Plan */}
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: "bold" }}
                      >
                        isNewsLetterSubscribed:
                      </Typography>
                    </Grid>
                    <Grid item>
                      {editMode ? (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.isNewsLetterSubscribed}
                              onChange={handleChange}
                              name="emailVerified"
                            />
                          }
                          label={
                            values.isNewsLetterSubscribed
                              ? "Subscribed"
                              : "Not Subscribed"
                          }
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{ fontWeight: "bold" }}
                        >
                          {values.isNewsLetterSubscribed ? "Yes" : "No"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {/* Row for Email Verified */}
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: "bold" }}
                      >
                        Account Verified:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {editMode ? (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.isVerified}
                              onChange={handleChange}
                              name="isVerified"
                            />
                          }
                          label={
                            values.isVerified ? "Verified" : "Not Verified"
                          }
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{ fontWeight: "bold" }}
                        >
                          {values.isVerified ? "Yes" : "No"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: "bold" }}
                      >
                        Account Blocked:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {editMode ? (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.accountBlocked}
                              onChange={handleChange}
                              name="accountBlocked"
                            />
                          }
                          label={values.accountBlocked ? "Yes" : "No"}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{ fontWeight: "bold" }}
                        >
                          {values.accountBlocked ? "Yes" : "No"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: "bold" }}
                      >
                        Withdrawal Blocked:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {editMode ? (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.withdrawalBlocked}
                              onChange={handleChange}
                              name="withdrawalBlocked"
                            />
                          }
                          label={values.withdrawalBlocked ? "Yes" : "N0"}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{ fontWeight: "bold" }}
                        >
                          {values.withdrawalBlocked ? "Yes" : "No"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: "bold" }}
                      >
                        Deposit Blocked:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {editMode ? (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.depositBlocked}
                              onChange={handleChange}
                              name="depositBlocked"
                            />
                          }
                          label={values.depositBlocked ? "Yes" : "N0"}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{ fontWeight: "bold" }}
                        >
                          {values.depositBlocked ? "Yes" : "No"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ fontWeight: "bold" }}
                      >
                        Pin Created:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {editMode ? (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.depositBlocked}
                              onChange={handleChange}
                              name="isPinCreated"
                            />
                          }
                          label={values.isPinCreated ? "Yes" : "N0"}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{ fontWeight: "bold" }}
                        >
                          {values.isPinCreated ? "Yes" : "No"}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  {/* Row for Ratings */}
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UserEditForm;
