import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../@types/common";
import logging from "../../logging";

import raffleService from "../../services/raffle.service";
import {
  RaffleSettingsModel,
  RaffleTicketModel,
  RaffleTransactionModel,
  RaffleWinnerCashOutPaymentModel,
  RaffleWinnerModel,
  RaffleWinnerPaymentRequest,
  SendMessageRequest,
} from "../../@types/raffle";
import { RaffleRequestPayload } from "../../@types/selection-model";

export const getAllRaffleTickets = createAsyncThunk<RaffleTicketModel[], void>(
  "raffle/getAllRaffleTickets",
  async (_, thunkAPI) => {
    try {
      return await raffleService.fetchAllRaffleTickets();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch all raffle tickets:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const getAllRaffleWinners = createAsyncThunk<RaffleWinnerModel[], void>(
  "raffle/getAllRaffleWinners",
  async (_, thunkAPI) => {
    try {
      return await raffleService.fetchAllRaffleWinners();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch all raffle winners:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const fetchPast3DaysRaffleWinners = createAsyncThunk<
  RaffleWinnerModel[],
  void
>("raffle/fetchPast3DaysRaffleWinners", async (_, thunkAPI) => {
  try {
    return await raffleService.fetchPast3DaysRaffleWinners();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error(
      "Failed to fetch past 3 days raffle winners:",
      serverErrorMessage,
    );
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const getAllRaffleWinnerPayouts = createAsyncThunk<
  RaffleWinnerCashOutPaymentModel[],
  void
>("raffle/getAllRaffleWinnerPayouts", async (_, thunkAPI) => {
  try {
    return await raffleService.fetchAllWinnersPayoutTransactions();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error(
      "Failed to fetch all raffle winners payouts:",
      serverErrorMessage,
    );
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});
export const updateRaffleWinnerData = createAsyncThunk<
  RaffleWinnerModel[],
  RaffleWinnerModel
>(
  "raffle/updateRaffleWinnerData",
  async (payload: RaffleWinnerModel, thunkAPI) => {
    try {
      return await raffleService.updateRaffleWinners(payload);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to update raffle winner data:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);
export const getAllRaffleTransactions = createAsyncThunk<
  RaffleTransactionModel[],
  void
>("raffle/getAllRaffleTransactions", async (_, thunkAPI) => {
  try {
    return await raffleService.fetchAllRaffleTransactions();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error(
      "Failed to fetch all raffle transaction:",
      serverErrorMessage,
    );
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const getRaffleSettingsData = createAsyncThunk<
  RaffleSettingsModel,
  void
>("raffle/getRaffleSettingsData", async (_, thunkAPI) => {
  try {
    return await raffleService.getRaffleSettings();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error(
      "Failed to fetch all raffle transaction:",
      serverErrorMessage,
    );
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const updateRaffleSettingsData = createAsyncThunk<
  any,
  RaffleSettingsModel
>(
  "raffle/updateRaffleSettingsData",
  async (data: RaffleSettingsModel, thunkAPI) => {
    try {
      return await raffleService.updateRaffleSettings(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error updating raffle settings info:", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);
export const payWinnerMoney = createAsyncThunk<any, RaffleWinnerPaymentRequest>(
  "raffle/payWinnerMoney",
  async (data: RaffleWinnerPaymentRequest, thunkAPI) => {
    try {
      return await raffleService.InitiateWinnerPayoutCheckout(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error updating raffle settings info:", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const initiateMonthlyDraws = createAsyncThunk<any, string>(
  "raffle/initiateMonthlyDraws",
  async (adminSecrete: string, thunkAPI) => {
    try {
      return await raffleService.monthlyWinnerTrigger(adminSecrete);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error in initiating monthly draws :", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const initiateDailyDraws = createAsyncThunk<any, string>(
  "raffle/initiateDailyDraws",
  async (adminSecrete: string, thunkAPI) => {
    try {
      return await raffleService.dailyWinnerTrigger(adminSecrete);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error in initiating daily draws :", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const maunalTicketPurchase = createAsyncThunk<any, string>(
  "raffle/maunalTicketPurchase",
  async (reference: string, thunkAPI) => {
    try {
      return await raffleService.manualUpdateAndCreateTicket(reference);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error in creating ticket :", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const sendUsersMessages = createAsyncThunk<any, SendMessageRequest>(
  "raffle/sendUsersMessages",
  async (payload: SendMessageRequest, thunkAPI) => {
    try {
      return await raffleService.sendParticipantMessages(payload);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error in sending users messages :", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const fetchDynamicTickets = createAsyncThunk<
  RaffleTicketModel[],
  RaffleRequestPayload
>(
  "raffle/fetchDynamicTickets",
  async (payload: RaffleRequestPayload, thunkAPI) => {
    try {
      return await raffleService.fetchDynamicRaffle(payload);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch tickets:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);

export const selectRaffleWinner = createAsyncThunk<
  RaffleWinnerModel[],
  RaffleRequestPayload
>(
  "raffle/selectRaffleWinner",
  async (payload: RaffleRequestPayload, thunkAPI) => {
    try {
      return await raffleService.selectManualWinner(payload);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed select winner:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  },
);
