import {
  Box,
  Paper,
  Checkbox,
  FormControlLabel,
  IconButton,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { Formik, FormikProps, Form } from "formik";

import { formatCurrency } from "../../../../../utils";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { RaffleWinnerModel } from "../../../../../@types/raffle";
import { rolesSelectorList } from "../../../../../selectors/roleSelector";
import { useDispatch, useSelector } from "react-redux";
import { currentUserInfomation } from "../../../../../selectors/authSelectors";
import { Spin } from "antd";
import { AppDispatch } from "../../../../../store";
import {
  getAllRaffleWinners,
  updateRaffleWinnerData,
} from "../../../../../store/thunks/raffle-thunk";
import logging from "../../../../../logging";

const winnerValidationSchema = Yup.object({
  winnerPhoneNumber: Yup.string().required("Phone number is required"),
  paymentPaid: Yup.boolean(),
});

interface Props {
  winner: RaffleWinnerModel;
}

const WinnerForm: React.FC<Props> = ({ winner }) => {
  const formikRef = useRef<FormikProps<any>>(null);

  const dispatch: AppDispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [editMode, setEditMode] = useState(false);

  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);

  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      },
    );
  };

  const initialValues: RaffleWinnerModel = {
    winnerID: winner.winnerID ?? "",
    price: winner.price ?? 0,
    drawDate: winner.drawDate ?? "",
    channelID: winner.channelID ?? "",
    raffleID: winner.raffleID ?? "",
    winnerPhoneNumber: winner.winnerPhoneNumber ?? "",
    paymentPaid: winner.paymentPaid ?? false,
  };

  const handleSubmit = async (values: RaffleWinnerModel, actions: any) => {
    const updatedValues = {
      ...values,
      price: parseFloat(values.price.toString()),
    };
    try {
      const resultAction = await dispatch(
        updateRaffleWinnerData(updatedValues),
      );
      if (updateRaffleWinnerData.fulfilled.match(resultAction)) {
        await dispatch(getAllRaffleWinners());
        logging.info("winner data updated successful!");
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  if (!winner || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={winnerValidationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form style={{ marginTop: 50 }}>
          {!winner?.paymentPaid && (
            <>
              {getRoleData(user.roleID)?.roleName === "Super Admin" ||
                (getRoleData(user.roleID)?.roleName === "Admin" && (
                  <Box display="flex" justifyContent="flex-end" mb={3} mt={2}>
                    {editMode ? (
                      <>
                        <Button
                          sx={{ marginRight: 2 }}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save Changes
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          color="secondary"
                          onClick={exitEditMode}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="button"
                        variant="contained"
                        onClick={enterEditMode}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                ))}
            </>
          )}

          <Grid container spacing={2}>
            <Paper elevation={3} sx={{ p: 4, width: "100%" }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontWeight: 600 }}
                  >
                    Is Payment Made:
                  </Typography>
                </Grid>
                <Grid item>
                  {editMode ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.paymentPaid}
                          onChange={handleChange}
                          name="paymentPaid"
                          color="primary"
                        />
                      }
                      label="Paid"
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      color={winner?.paymentPaid ? "green" : "error"}
                      sx={{
                        fontWeight: 900,
                        fontSize: isMobile ? 18 : 22,
                        mb: 3,
                      }}
                    >
                      {winner?.paymentPaid ? "Yes" : "No"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontWeight: 600 }}
                  >
                    Amount Won:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{
                      fontWeight: 900,
                      fontSize: isMobile ? 15 : 17,
                      mb: 3,
                    }}
                  >
                    {formatCurrency(winner?.price, true)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontWeight: 600 }}
                  >
                    Winner's Number:
                  </Typography>
                </Grid>
                <Grid item>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <IconButton
                      onClick={() =>
                        handleCopyToClipboard(winner?.winnerPhoneNumber)
                      }
                      size="small"
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                    <Typography
                      variant="body1"
                      color="primary"
                      sx={{
                        fontWeight: 900,
                        fontSize: isMobile ? 15 : 17,
                        maxWidth: isMobile ? 100 : 380, // Adjust based on your layout needs
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {winner?.winnerPhoneNumber}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontWeight: 600 }}
                  >
                    Winner ID:
                  </Typography>
                </Grid>
                <Grid item>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <IconButton
                      onClick={() => handleCopyToClipboard(winner?.winnerID)}
                      size="small"
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                    <Typography
                      variant="body1"
                      color="primary"
                      sx={{
                        fontWeight: 900,
                        fontSize: isMobile ? 15 : 17,
                        maxWidth: isMobile ? 100 : 380, // Adjust based on your layout needs
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {winner?.winnerID}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontWeight: 600 }}
                  >
                    Raffle ID:
                  </Typography>
                </Grid>
                <Grid item>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <IconButton
                      onClick={() => handleCopyToClipboard(winner?.raffleID)}
                      size="small"
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                    <Typography
                      variant="body1"
                      color="primary"
                      sx={{
                        fontWeight: 900,
                        fontSize: isMobile ? 15 : 17,
                        maxWidth: isMobile ? 100 : 380, // Adjust based on your layout needs
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {winner?.raffleID}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontWeight: 600 }}
                  >
                    Channel Type:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{
                      fontWeight: 900,
                      fontSize: isMobile ? 15 : 17,
                      mb: 3,
                    }}
                  >
                    {winner?.channelID}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Draw Date:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="primary"
                      sx={{
                        fontWeight: 900,
                        fontSize: isMobile ? 15 : 17,
                        mb: 3,
                      }}
                    >
                      {dayjs(winner?.drawDate).format("YYYY-MM-DD")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default WinnerForm;
