import React, { useEffect, useMemo } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
import { AirtimeResponse } from "../../../../@types/products-model";
import {
  productsLoadingState,
  vtuPurchases,
} from "../../../../selectors/productSelector";
import dayjs from "dayjs";

const VTUPurchaseList = () => {
  const dispatch: AppDispatch = useDispatch();
  const vtuPurchase: AirtimeResponse[] | null = useSelector(vtuPurchases);

  const isLoading = useSelector(productsLoadingState);
  const search = useSelector(searchQuery);

  const theme = useTheme();

  const columns: CustomColumn<AirtimeResponse>[] = useMemo(
    () => [
      { id: "requestId", label: "RequestID" },
      { id: "phoneNumber", label: "Phone Number" },
      { id: "amount", label: "Amount" },
      { id: "discount", label: "Discount" },
      { id: "status", label: "Status" },
      { id: "errorMessage", label: "Error Message" },
      {
        id: "createdAt",
        label: "Date",
        format: (value) => dayjs(value).format("DD MMMM YYYY, HH:mm"),
      },
    ],
    [],
  );

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const sortedTransactions = useMemo(() => {
    // Make a copy of filteredTransactions to avoid mutating a potentially immutable array
    const transactionsCopy = (vtuPurchase || []).slice();
    return transactionsCopy.sort(
      (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
    );
  }, [vtuPurchase]);

  const filteredWallets = useMemo(() => {
    if (!search) return sortedTransactions;
    return sortedTransactions.filter(
      (vtu) =>
        vtu.amount.toLowerCase().includes(search.toLowerCase()) ||
        vtu.requestId.toLowerCase().includes(search.toLowerCase()) ||
        vtu.status.toString().includes(search.toLowerCase()),
    );
  }, [sortedTransactions, search]);

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            VTU Purchases
          </Typography>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={filteredWallets}
              columns={columns}
              paginationOptions={{
                rowsPerPage: 20,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default VTUPurchaseList;
