import React from "react";
import { RaffleWinnerCashOutPaymentModel } from "../../../../../@types/raffle";
import dayjs from "dayjs";
import {
  Paper,
  Typography,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

interface Props {
  payout: RaffleWinnerCashOutPaymentModel;
}

const PayoutStatus: React.FC<Props> = ({ payout }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to determine the border color based on status
  const getBorderColor = (status: string) => {
    switch (status) {
      case "success":
        return "green";
      case "pending":
        return "orange";
      case "failed":
        return "red";
      default:
        return "grey";
    }
  };

  // Function to copy the transaction ID to the clipboard
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(payout.tx_ref).then(
      () => alert("Transaction ID copied to clipboard!"),
      () => alert("Failed to copy text. Please try again."),
    );
  };

  return (
    <Grid container spacing={2}>
      <Paper
        elevation={3}
        sx={{
          border: `2px solid ${getBorderColor(payout.status)}`,
          borderRadius: "8px",
          p: 4,
          width: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ fontWeight: 600 }}
            >
              Status
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color={getBorderColor(payout.status)}
              sx={{
                fontWeight: 900,
                fontSize: isMobile ? 18 : 22,
                mb: 3,
              }}
            >
              {payout.status.toUpperCase()}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ fontWeight: 600 }}
            >
              Transaction ID:
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton
                onClick={handleCopyToClipboard}
                size="small"
                sx={{ marginRight: "8px" }} // Optional: Adjust right margin if needed
              >
                <FileCopyOutlinedIcon />
              </IconButton>
              <Typography
                variant="body1"
                color="primary"
                sx={{
                  fontWeight: 900,
                  fontSize: isMobile ? 15 : 17,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: isMobile ? 100 : 380, // Adjust based on layout needs
                }}
              >
                {payout.tx_ref}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ fontWeight: 600 }}
            >
              Draw Date:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color="primary"
              sx={{
                fontWeight: 900,
                fontSize: isMobile ? 15 : 17,
                mb: 3,
              }}
            >
              {dayjs(payout.created_at).format(" MMMM D, YYYY :: HH:mm:ss")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PayoutStatus;
