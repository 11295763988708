import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GameRecord, MonthlyRecordsData } from "../../@types/game-model";
import ReusableTable from "../reusable-table";
import { CustomColumn } from "../../@types/table";
import { TransactionAggregates, TransactionSummary } from "../../@types/wallet";
import dayjs from "dayjs";
import {
  PurchaseSummary,
  VtuPurchaseAggregates,
} from "../../@types/products-model";

interface GameRecordModalProps {
  open: boolean;
  onClose: () => void;
  record: GameRecord;
  tabNumber: number;
  transactionData: TransactionAggregates | null; // Assuming it can be null
  vtuPurchases: VtuPurchaseAggregates | null;
}

interface ExtendedMonthlyRecordsData extends MonthlyRecordsData {
  monthlyRTP: number; // Calculated property
}

const GameRecordModal: React.FC<GameRecordModalProps> = ({
  open,
  onClose,
  record,
  transactionData,
  tabNumber,
  vtuPurchases,
}) => {
  const [activeTab, setActiveTab] = useState(tabNumber);

  useEffect(() => {
    setActiveTab(tabNumber);
  }, [tabNumber]);

  const depositTransaction = React.useMemo(() => {
    return [...(transactionData?.deposits.monthlyTransactions || [])].sort(
      (a, b) => dayjs(b.month).unix() - dayjs(a.month).unix(),
    );
  }, [transactionData?.deposits.monthlyTransactions]);

  const cashOutTransaction = React.useMemo(() => {
    return [...(transactionData?.cashOuts.monthlyTransactions || [])].sort(
      (a, b) => dayjs(b.month).unix() - dayjs(a.month).unix(),
    );
  }, [transactionData?.cashOuts.monthlyTransactions]);

  const vtuRecords = React.useMemo(() => {
    return [...(vtuPurchases?.monthlyPurchases || [])].sort(
      (a, b) => dayjs(b.month).unix() - dayjs(a.month).unix(),
    );
  }, [vtuPurchases?.monthlyPurchases]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const transformedData: ExtendedMonthlyRecordsData[] =
    record.monthlyRecordsData
      .map((data) => ({
        ...data,
        monthlyRTP: (data.totalWon / data.totalSpent) * 100,
      }))
      .sort((a, b) => dayjs(b.month).unix() - dayjs(a.month).unix());
  const totalRTP = (record.totalWon / record.totalSpent) * 100;

  const columns: CustomColumn<ExtendedMonthlyRecordsData>[] = [
    { id: "month", label: "Month", format: (value) => value },
    {
      id: "totalSpin",
      label: "Total Spins",
      format: (value) => value.toLocaleString(),
    },
    {
      id: "totalSpent",
      label: "Total Spent",
      format: (value) => `₦ ${value.toLocaleString()}`,
    },
    {
      id: "totalWon",
      label: "Total Won",
      format: (value) => `₦ ${value.toLocaleString()}`,
    },
    {
      id: "monthlyRTP",
      label: "Monthly RTP",
      format: (value) => `${value.toFixed(2)}%`,
    },
  ];

  const transactionColumns: CustomColumn<TransactionSummary>[] = [
    { id: "month", label: "Month", format: (value) => value },
    {
      id: "totalAmount",
      label: "Total Amount",
      format: (value) => `₦${value.toLocaleString()}`,
    },
    {
      id: "transactionCount",
      label: "Transaction Count",
      format: (value) => value.toLocaleString(),
    },
  ];

  const vtuPuchaseColumns: CustomColumn<PurchaseSummary>[] = [
    { id: "month", label: "Month", format: (value) => value },
    {
      id: "totalAmount",
      label: "Total Amount",
      format: (value) => `₦ ${value.toLocaleString()}`,
    },
    {
      id: "purchaseCount",
      label: "No. of  Purchase",
      format: (value) => value.toLocaleString(),
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Game Record Details
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="tabs for record details"
        >
          <Tab label="Records" />
          <Tab label="Deposits" />
          <Tab label="Cash-Outs" />
          <Tab label="VTU Purchases" />
        </Tabs>
        {activeTab === 0 && (
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h6">Overview</Typography>
                <Typography gutterBottom>
                  Total Spins: {record.totalSpin}
                </Typography>
                <Typography gutterBottom>
                  Total Spent: ₦ {record.totalSpent.toLocaleString()}
                </Typography>
                <Typography gutterBottom>
                  Total Won: ₦ {record.totalWon.toLocaleString()}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 100,
                  height: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f0f0f0", // Light grey background
                  borderRadius: "50%", // Make it circular
                  boxShadow: 3, // Adds shadow for depth
                }}
              >
                <Typography variant="h5" component="div" color="primary">
                  {totalRTP.toFixed(1)}%
                </Typography>
              </Box>
            </Box>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Monthly Records
            </Typography>
            <ReusableTable
              data={transformedData}
              columns={columns}
              paginationOptions={{
                showPagination: true,
                rowsPerPageOptions: [5, 10, 15],
              }}
            />
          </Box>
        )}
        {activeTab === 1 && transactionData?.deposits && (
          <Box>
            <ReusableTable
              data={depositTransaction}
              columns={transactionColumns}
              paginationOptions={{
                showPagination: true,
                rowsPerPageOptions: [5, 10, 15],
              }}
            />
          </Box>
        )}
        {activeTab === 2 && transactionData?.cashOuts && (
          <Box>
            <ReusableTable
              data={cashOutTransaction}
              columns={transactionColumns}
              paginationOptions={{
                showPagination: true,
                rowsPerPageOptions: [5, 10, 15],
              }}
            />
          </Box>
        )}
        {activeTab === 3 && vtuPurchases?.monthlyPurchases && (
          <Box>
            <ReusableTable
              data={vtuRecords}
              columns={vtuPuchaseColumns}
              paginationOptions={{
                showPagination: true,
                rowsPerPageOptions: [5, 10, 15],
              }}
            />
          </Box>
        )}
      </DialogContent>
      <Button onClick={onClose} color="primary" style={{ margin: 16 }}>
        Close
      </Button>
    </Dialog>
  );
};

export default GameRecordModal;
