import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAuthenticatedUser } from "./auth-thunk";

import logging from "../../logging";
import { getAllMessages, getUsers } from "./users-thunks";
import { getStaffs } from "./staff-thunks";
import {
  getAllBanksDetails,
  getAllBuyBackTransactions,
  getAllPayouts,
  getAllTransactions,
  getAllTrasactionAmounts,
  getAllWallets,
  getSumOfAllInboundTransactions,
} from "./wallet-thunk";
import { getRoles } from "./roles-thunks";
import {
  fetchAllUserProductsHistory,
  fetchAllVtuPurchases,
  getProducts,
  getVtuAggregatedAmounts,
} from "./product-thunk";
import { getBoxPackages } from "./box-thunk";
import {
  getAllUsersSpins,
  getGameRecords,
  getGameSettings,
} from "./game-thunk";
import {
  fetchPast3DaysRaffleWinners,
  getAllRaffleTickets,
  getAllRaffleTransactions,
  getAllRaffleWinnerPayouts,
  getAllRaffleWinners,
  getRaffleSettingsData,
} from "./raffle-thunk";
import { getAllUssdSmsCost } from "./sms-ussd-thunk";

export const preloadDataAfterLogin = createAsyncThunk(
  "auth/preloadData",
  async (_, thunkAPI) => {
    try {
      // First, fetch the roles as it's a dependency for fetchAuthenticatedUser
      // await thunkAPI.dispatch(getRoles());

      // Once roles are fetched, fetch the authenticated user

      // If there are other non-dependent thunks, you can use Promise.all to fetch them concurrently
      await Promise.all([
        thunkAPI.dispatch(fetchAuthenticatedUser()),
        thunkAPI.dispatch(getGameSettings()),
        thunkAPI.dispatch(getProducts()),
        thunkAPI.dispatch(getGameRecords()),
        thunkAPI.dispatch(getBoxPackages()),
        thunkAPI.dispatch(getUsers()),
        thunkAPI.dispatch(getSumOfAllInboundTransactions()),
        thunkAPI.dispatch(getAllBuyBackTransactions()),
        thunkAPI.dispatch(getAllTransactions()),
        thunkAPI.dispatch(getAllTrasactionAmounts()),
        thunkAPI.dispatch(getAllWallets()),
        thunkAPI.dispatch(getStaffs()),
        thunkAPI.dispatch(getRoles()),
        thunkAPI.dispatch(fetchAllUserProductsHistory()),
        thunkAPI.dispatch(getAllUsersSpins()),
        thunkAPI.dispatch(getAllBanksDetails()),
        thunkAPI.dispatch(getAllMessages()),
        thunkAPI.dispatch(getVtuAggregatedAmounts()),
        thunkAPI.dispatch(fetchAllVtuPurchases()),
        thunkAPI.dispatch(getAllRaffleTickets()),
        thunkAPI.dispatch(getAllRaffleWinners()),
        thunkAPI.dispatch(getRaffleSettingsData()),
        thunkAPI.dispatch(getAllRaffleTransactions()),
        thunkAPI.dispatch(getAllRaffleWinnerPayouts()),
        thunkAPI.dispatch(fetchPast3DaysRaffleWinners()),
        thunkAPI.dispatch(getAllUssdSmsCost()),
      ]);
    } catch (error) {
      if (error instanceof Error) {
        logging.error("Error preloading data:", error.message);
      }
    }
  },
);
