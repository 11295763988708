import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Select,
  Button as MUIButton,
  SelectChangeEvent,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { usersSelectorLoadingState } from "../../../../selectors/userSelector";
// import { UserModel } from "../../../../@types/user-model";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
// import { WalletInfo } from "../../../../@types/wallet";
import { useNavigate } from "react-router-dom";
import { RaffleWinnerModel } from "../../../../@types/raffle";
import dayjs from "dayjs";
import { raffleWinners } from "../../../../selectors/raffleSelector";
import { Button, Spin } from "antd";
import { formatCurrency } from "../../../../utils";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { staffSelectorData } from "../../../../selectors/staffSelector";
import InfoIcon from "@mui/icons-material/InfoOutlined";

const RaffleWinnersPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const raffleWinnersData: RaffleWinnerModel[] | null =
    useSelector(raffleWinners);
  const [sortDirection, setSortDirection] = useState("descending");

  const isLoading = useSelector(usersSelectorLoadingState);
  const search = useSelector(searchQuery);
  const roles = useSelector(rolesSelectorList);
  const currentUser = useSelector(currentUserInfomation);
  const partners = useSelector(staffSelectorData);

  const theme = useTheme();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedPartner, setSelectedPartner] = useState<string>("");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser && roles) {
      const userRole = roles.find((role) => role.roleID === currentUser.roleID);
      if (userRole?.roleName === "ReadOnlyPartner") {
        setSelectedPartner(currentUser.staffID); // Set the current user as the selected partner if they are a ReadOnlyPartner
      } else {
        setSelectedPartner("All"); // Set to 'All' if the current user is not a ReadOnlyPartner
      }
    }
  }, [currentUser, roles]);

  const columns: CustomColumn<RaffleWinnerModel>[] = useMemo(
    () => [
      { id: "winnerPhoneNumber", label: "User" },
      {
        id: "winnerID",
        label: "Winner ID",
      },
      {
        id: "price",
        label: "Win Price (NGN)",
        format: (value) => formatCurrency(value),
      },
      {
        id: "paymentPaid",
        label: "Status",
        format: (value: boolean) => (
          <Typography
            style={{
              color: value ? "green" : "red", // Green for 'Paid', red for 'Pending'
              fontWeight: "bold",
            }}
          >
            {value ? "Paid" : "Pending"}
          </Typography>
        ),
      },
      {
        id: "drawDate",
        label: "Date",
        format: (value: string) => dayjs(value).format("YYYY-MM-DD"), // assuming the string is in a recognizable date format
      },
    ],
    [],
  );

  const filteredWinners = useMemo(() => {
    let winners = [...(raffleWinnersData || [])]; // Create a shallow copy of the array

    if (selectedPartner !== "All") {
      const partner = partners.find((p) => p.staffID === selectedPartner);
      winners = winners.filter(
        (winner) => winner.businessID === partner?.businessID,
      );
    }

    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      winners = winners.filter(
        (winner) =>
          winner.winnerPhoneNumber.toLowerCase().includes(lowerCaseSearch) ||
          winner.raffleID.toLowerCase().includes(lowerCaseSearch) ||
          winner.winnerID.toLowerCase().includes(lowerCaseSearch) ||
          winner.price.toString().toLowerCase().includes(lowerCaseSearch) ||
          winner.paymentPaid.toString().toLowerCase().includes(lowerCaseSearch),
      );
    }

    return winners.sort((a, b) =>
      a.drawDate < b.drawDate
        ? sortDirection === "ascending"
          ? -1
          : 1
        : sortDirection === "ascending"
          ? 1
          : -1,
    );
  }, [raffleWinnersData, selectedPartner, partners, search, sortDirection]);

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "ascending" ? "descending" : "ascending",
    );
  };

  const handleUserClick = (winner: RaffleWinnerModel) => {
    navigate(`/dashboard/raffle-winners/${winner.winnerID}`);
  };

  const paidTransactions = useMemo(() => {
    return filteredWinners?.filter((winner) => winner.paymentPaid) || [];
  }, [filteredWinners]);

  const pendingTransactions = useMemo(() => {
    return filteredWinners?.filter((winner) => !winner.paymentPaid) || [];
  }, [filteredWinners]);

  const handlePartnerChange = (event: SelectChangeEvent<string>) => {
    setSelectedPartner(event.target.value);
  };

  const filteredPartners = useMemo(() => {
    // Find the roleID for 'ReadOnlyPartner' from the roles list
    const readOnlyPartnerRoleID = roles.find(
      (role) => role.roleName === "ReadOnlyPartner",
    )?.roleID;
    // Filter partners who have the 'ReadOnlyPartner' roleID
    return partners.filter(
      (partner) => partner.roleID === readOnlyPartnerRoleID,
    );
  }, [partners, roles]);

  const isAdmin = useMemo(() => {
    const adminRoles = ["Super Admin", "Admin"]; // Adjust based on your role names
    const currentUserRole =
      roles.find((role) => role.roleID === currentUser?.roleID)?.roleName || "";
    return adminRoles.includes(currentUserRole);
  }, [currentUser, roles]);

  if (
    isLoading ||
    !roles ||
    !currentUser ||
    !selectedPartner ||
    !raffleWinnersData
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };
  const roleName =
    getRoleData(currentUser?.roleID)?.roleName || "ReadOnlyPartner";

  const handleNavigate = () => {
    navigate(`/select-winners`);
  };

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Raffle Winners
            </Typography>
            <Button color="secondary" onClick={toggleSortDirection}>
              Toggle Date Sort
            </Button>
          </Box>
          <Box>
            <MUIButton
              onClick={handleNavigate}
              variant="contained"
              color="secondary"
            >
              Select Winners
            </MUIButton>
          </Box>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent={"flex-end"}
            sx={{ p: 2 }}
          >
            {isAdmin && (
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="partner-select-label">
                    Select Partner
                  </InputLabel>
                  <Select
                    labelId="partner-select-label"
                    id="partner-select"
                    value={selectedPartner}
                    onChange={handlePartnerChange}
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {filteredPartners.map((partner) => (
                      <MenuItem key={partner.staffID} value={partner.staffID}>
                        {partner.firstName} {partner.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Payment Status Tabs"
          >
            <Tab label="Paid" />
            <Tab label="Pending" />
          </Tabs>
        </Grid>

        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {activeTab === 0 ? (
              paidTransactions.length > 0 ? (
                <ReusableTable
                  data={paidTransactions}
                  columns={columns}
                  paginationOptions={{
                    rowsPerPage: 20,
                    rowsPerPageOptions: [10, 20, 30],
                    showPagination: true,
                  }}
                  enableRowClick={true}
                  onRowClick={(item) => {
                    if (roleName === "Super Admin" || roleName === "Admin") {
                      handleUserClick(item);
                    }
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 200,
                    mt: 4,
                  }}
                >
                  <InfoIcon color="action" sx={{ fontSize: 60 }} />
                  <Typography variant="subtitle1" color="textSecondary">
                    No existing paid winners.
                  </Typography>
                </Box>
              )
            ) : pendingTransactions.length > 0 ? (
              <ReusableTable
                data={pendingTransactions}
                columns={columns}
                paginationOptions={{
                  rowsPerPage: 20,
                  rowsPerPageOptions: [10, 20, 30],
                  showPagination: true,
                }}
                enableRowClick={true}
                onRowClick={(item) => {
                  if (roleName === "Super Admin" || roleName === "Admin") {
                    handleUserClick(item);
                  }
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 200,
                  mt: 4,
                }}
              >
                <InfoIcon color="action" sx={{ fontSize: 60 }} />
                <Typography variant="subtitle1" color="textSecondary">
                  No unpaid winners.
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RaffleWinnersPage;
