import React, { useEffect, useRef, useState } from "react";
import { Grid, Paper, Typography, TextField, Box, Button } from "@mui/material";

import { Formik, Form, FormikProps } from "formik";
import { formatCurrency } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";

import { Spin } from "antd";
import { AppDispatch } from "../../../../store";
import logging from "../../../../logging";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import {
  gameLoadingState,
  gameSettingsData,
} from "../../../../selectors/gameSelector";
import { SettingsData, TierThreshold } from "../../../../@types/game-model";
import {
  getGameSettings,
  updateGameSettings,
} from "../../../../store/thunks/game-thunk";
import { useNavigate } from "react-router-dom";

const GameSettings = () => {
  const isLoading = useSelector(gameLoadingState);
  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);
  const gameSettings = useSelector(gameSettingsData);

  const [editMode, setEditMode] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const paymentOption = ["prod", "testing", "maintenance"];

  const formikRef = useRef<FormikProps<any>>(null);

  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };

  useEffect(() => {
    // Ensure that both user and roles are fully loaded
    if (user && roles.length > 0) {
      const roleData = getRoleData(user.roleID);
      // Redirect if the user is not a Super Admin or Admin
      if (
        roleData &&
        roleData.roleName !== "Super Admin" &&
        roleData.roleName !== "Admin"
      ) {
        navigate("/dashboard");
      }
    }
  }, [user, roles, navigate]); // Include all dependencies here

  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  const handleSubmit = async (values: SettingsData, actions: any) => {
    const updatedValues = {
      ...values,
      fixedCost: parseFloat(values.fixedCost.toString()),
      variableCost: parseFloat(values.variableCost.toString()),
      minimumSpinsPerMonth: parseFloat(values.minimumSpinsPerMonth.toString()),
      minimumSpinsForAdjustment: parseFloat(
        values.minimumSpinsForAdjustment.toString(),
      ),
      minRTP: parseFloat(values.minRTP.toString()),
      maxRTP: parseFloat(values.maxRTP.toString()),
      scaleDownBoost: parseFloat(values.scaleDownBoost.toString()),
      scaleUpBoost: parseFloat(values.scaleUpBoost.toString()),
      minReferralSpent: parseFloat(values.minReferralSpent.toString()),
      bonusReferralAmount: parseFloat(values.bonusReferralAmount.toString()),
      vtuMinCashOut: parseFloat(values.vtuMinCashOut.toString()),
    };
    try {
      const resultAction = await dispatch(updateGameSettings(updatedValues));
      if (updateGameSettings.fulfilled.match(resultAction)) {
        await dispatch(getGameSettings());
        logging.info("Settings data updated successful!");
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  if (!gameSettings || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const defaultTierThreshold: TierThreshold = {
    tier1Threshold: 0,
    tier1CommissionPct: 0,
    tier2Threshold: 0,
    tier2CommissionPct: 0,
    tier3Threshold: 0,
    tier3CommissionPct: 0,
  };

  const initialValues: SettingsData = {
    settingsID: gameSettings?.settingsID ?? "",
    minRTP: gameSettings?.minRTP ?? 0,
    maxRTP: gameSettings?.maxRTP ?? 0,
    minesRTP: gameSettings?.minesRTP ?? 0,
    minesTotalReelsRequired: gameSettings?.minesTotalReelsRequired ?? 0,
    minesMinValuableProducts: gameSettings?.minesMinValuableProducts ?? 0,
    fixedCost: gameSettings?.fixedCost ?? 0,
    maintenance: gameSettings?.maintenance ?? false,
    maintenanceMsg: gameSettings?.maintenanceMsg ?? "",
    variableCost: gameSettings?.variableCost ?? 0,
    minimumSpinsPerMonth: gameSettings?.minimumSpinsPerMonth ?? 0,
    minimumSpinsForAdjustment: gameSettings?.minimumSpinsForAdjustment ?? 0,
    levelThresholds: gameSettings?.levelThresholds ?? [],
    tierThreshold: gameSettings?.tierThreshold ?? defaultTierThreshold,
    overallRTP: gameSettings?.overallRTP ?? false,
    enableIndividualBoost: gameSettings?.enableIndividualBoost ?? false,
    scaleDownBoost: gameSettings?.scaleDownBoost ?? 0,
    scaleUpBoost: gameSettings?.scaleUpBoost ?? 0,
    minReferralSpent: gameSettings?.minReferralSpent ?? 0,
    bonusReferralAmount: gameSettings?.bonusReferralAmount ?? 0,
    vtuMinCashOut: gameSettings?.vtuMinCashOut ?? 0,
    showGoogleApplePay: gameSettings?.showGoogleApplePay ?? false,
    allowedPayment: gameSettings?.allowedPayment ?? "",
  };
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // validationSchema={UserSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Game Settings
                </Typography>
              </Box>
            </Grid>
            {getRoleData(user.roleID)?.roleName === "Super Admin" && (
              <Box display="flex" justifyContent="flex-end" mb={3}>
                {editMode ? (
                  <>
                    <Button
                      sx={{ marginRight: 2 }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save Changes
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={exitEditMode}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={enterEditMode}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            )}
            <Grid container spacing={2}>
              <Paper elevation={2} sx={{ p: 4, width: "100%" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Min RTP:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Min RTP"
                        name="minRTP"
                        margin="normal"
                        value={values.minRTP}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={touched.minRTP && !!errors.minRTP}
                        helperText={
                          touched.minRTP && typeof errors.minRTP === "string"
                            ? errors.minRTP
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {`% ${gameSettings.minRTP}`}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Max RTP:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Max RTP"
                        name="maxRTP"
                        margin="normal"
                        value={values.maxRTP}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={touched.maxRTP && !!errors.maxRTP}
                        helperText={
                          touched.maxRTP && typeof errors.maxRTP === "string"
                            ? errors.maxRTP
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {`% ${gameSettings.maxRTP}`}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Fixed Cost:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Fixed Cost"
                        name="fixedCost"
                        margin="normal"
                        value={values.fixedCost}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={touched.fixedCost && !!errors.fixedCost}
                        helperText={
                          touched.fixedCost &&
                          typeof errors.fixedCost === "string"
                            ? errors.fixedCost
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {formatCurrency(gameSettings.fixedCost, true)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Minimum VTU Cash Out:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="VTU Min CashOut"
                        name="vtuMinCashOut"
                        margin="normal"
                        value={values.vtuMinCashOut}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={touched.vtuMinCashOut && !!errors.vtuMinCashOut}
                        helperText={
                          touched.vtuMinCashOut &&
                          typeof errors.vtuMinCashOut === "string"
                            ? errors.vtuMinCashOut
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {formatCurrency(gameSettings.vtuMinCashOut, true)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Variable Cost:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Variable Cost"
                        name="variableCost"
                        margin="normal"
                        value={values.variableCost}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={touched.variableCost && !!errors.variableCost}
                        helperText={
                          touched.variableCost &&
                          typeof errors.variableCost === "string"
                            ? errors.variableCost
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {formatCurrency(gameSettings.variableCost, true)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Minimum SpinsPer Month:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Min Spin Month"
                        name="minimumSpinsPerMonth"
                        margin="normal"
                        value={values.minimumSpinsPerMonth}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={
                          touched.minimumSpinsPerMonth &&
                          !!errors.minimumSpinsPerMonth
                        }
                        helperText={
                          touched.minimumSpinsPerMonth &&
                          typeof errors.minimumSpinsPerMonth === "string"
                            ? errors.minimumSpinsPerMonth
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {gameSettings.minimumSpinsPerMonth}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Minimum Sins For Adjustment:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Min Sin Adjus"
                        name="minimumSpinsForAdjustment"
                        margin="normal"
                        value={values.minimumSpinsForAdjustment}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={
                          touched.minimumSpinsForAdjustment &&
                          !!errors.minimumSpinsForAdjustment
                        }
                        helperText={
                          touched.minimumSpinsForAdjustment &&
                          typeof errors.minimumSpinsForAdjustment === "string"
                            ? errors.minimumSpinsForAdjustment
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {gameSettings.minimumSpinsForAdjustment}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Scale Down Boost:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Scale Down"
                        name="scaleDownBoost"
                        margin="normal"
                        value={values.scaleDownBoost}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={
                          touched.scaleDownBoost && !!errors.scaleDownBoost
                        }
                        helperText={
                          touched.scaleDownBoost &&
                          typeof errors.scaleDownBoost === "string"
                            ? errors.scaleDownBoost
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {gameSettings.scaleDownBoost}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Scale Up Boost:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Scale Up"
                        name="scaleUpBoost"
                        margin="normal"
                        value={values.scaleUpBoost}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={touched.scaleUpBoost && !!errors.scaleUpBoost}
                        helperText={
                          touched.scaleUpBoost &&
                          typeof errors.scaleUpBoost === "string"
                            ? errors.scaleUpBoost
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {gameSettings.scaleUpBoost}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Min Referral Spent:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Referaal Spent"
                        name="minReferralSpent"
                        margin="normal"
                        value={values.minReferralSpent}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={
                          touched.minReferralSpent && !!errors.minReferralSpent
                        }
                        helperText={
                          touched.minReferralSpent &&
                          typeof errors.minReferralSpent === "string"
                            ? errors.minReferralSpent
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {formatCurrency(gameSettings.minReferralSpent, true)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Bonus Referral Amount:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Scale Up"
                        name="bonusReferralAmount"
                        margin="normal"
                        value={values.bonusReferralAmount}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={
                          touched.bonusReferralAmount &&
                          !!errors.bonusReferralAmount
                        }
                        helperText={
                          touched.bonusReferralAmount &&
                          typeof errors.bonusReferralAmount === "string"
                            ? errors.bonusReferralAmount
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {formatCurrency(gameSettings.bonusReferralAmount, true)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      On Maintenance:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Maintenance"
                        name="maintenance"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        value={values.maintenance ? "yes" : "no"}
                        onChange={(event) => {
                          const value = event.target.value === "yes"; // Convert back to boolean
                          setFieldValue("maintenance", value); // Manually update the field value
                        }}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </TextField>
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 22, mb: 3 }}
                      >
                        {gameSettings?.maintenance ? "Yes" : "No"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Allow Payment:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Allow Payment"
                        name="allowedPayment"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                          disabled: !editMode,
                        }}
                        value={values.allowedPayment}
                        onChange={(event) => {
                          const value = event.target.value; // Convert back to boolean
                          setFieldValue("allowedPayment", value); // Manually update the field value
                        }}
                      >
                        {paymentOption.map((opt, index) => {
                          return (
                            <option key={index} value={opt}>
                              {opt}
                            </option>
                          );
                        })}
                      </TextField>
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 22, mb: 3 }}
                      >
                        {gameSettings?.allowedPayment}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Overall RTP:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Overall Rtp"
                        name="overallRTP"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        value={values.overallRTP ? "yes" : "no"}
                        onChange={(event) => {
                          const value = event.target.value === "yes"; // Convert back to boolean
                          setFieldValue("overallRTP", value); // Manually update the field value
                        }}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </TextField>
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 22, mb: 3 }}
                      >
                        {gameSettings?.overallRTP ? "Yes" : "No"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Enable Individual Boost:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Enable Boost"
                        name="enableIndividualBoost"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        value={values.enableIndividualBoost ? "yes" : "no"}
                        onChange={(event) => {
                          const value = event.target.value === "yes"; // Convert back to boolean
                          setFieldValue("enableIndividualBoost", value); // Manually update the field value
                        }}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </TextField>
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 22, mb: 3 }}
                      >
                        {gameSettings?.enableIndividualBoost ? "Yes" : "No"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Show Google Or Apple:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Google & Apple"
                        name="showGoogleApplePay"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        value={values.showGoogleApplePay ? "yes" : "no"}
                        onChange={(event) => {
                          const value = event.target.value === "yes"; // Convert back to boolean
                          setFieldValue("showGoogleApplePay", value); // Manually update the field value
                        }}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </TextField>
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 22, mb: 3 }}
                      >
                        {gameSettings?.showGoogleApplePay ? "Yes" : "No"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default GameSettings;
