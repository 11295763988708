import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { usersSelectorLoadingState } from "../../../../selectors/userSelector";
// import { UserModel } from "../../../../@types/user-model";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
// import { WalletInfo } from "../../../../@types/wallet";
// import { useNavigate } from "react-router-dom";
import { RaffleTransactionModel } from "../../../../@types/raffle";
import dayjs from "dayjs";
import { raffleTransactions } from "../../../../selectors/raffleSelector";
import { Button, Spin } from "antd";
import { formatCurrency } from "../../../../utils";
import { Tabs, Tab } from "@mui/material";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { staffSelectorData } from "../../../../selectors/staffSelector";
import InfoIcon from "@mui/icons-material/InfoOutlined";

const RafflePaymentPage = () => {
  const dispatch: AppDispatch = useDispatch();
  // const navigate = useNavigate();
  const raffleTransaction: RaffleTransactionModel[] | null =
    useSelector(raffleTransactions);
  const [sortDirection, setSortDirection] = useState("descending");

  const isLoading = useSelector(usersSelectorLoadingState);
  const search = useSelector(searchQuery);
  const [selectedPartner, setSelectedPartner] = useState<string>("");
  const roles = useSelector(rolesSelectorList);
  const currentUser = useSelector(currentUserInfomation);
  const partners = useSelector(staffSelectorData);
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (currentUser && roles) {
      const userRole = roles.find((role) => role.roleID === currentUser.roleID);
      if (userRole?.roleName === "ReadOnlyPartner") {
        setSelectedPartner(currentUser.staffID); // Set the current user as the selected partner if they are a ReadOnlyPartner
      } else {
        setSelectedPartner("All"); // Set to 'All' if the current user is not a ReadOnlyPartner
      }
    }
  }, [currentUser, roles]);

  const filteredPartners = useMemo(() => {
    // Find the roleID for 'ReadOnlyPartner' from the roles list
    const readOnlyPartnerRoleID = roles.find(
      (role) => role.roleName === "ReadOnlyPartner",
    )?.roleID;
    // Filter partners who have the 'ReadOnlyPartner' roleID
    return partners.filter(
      (partner) => partner.roleID === readOnlyPartnerRoleID,
    );
  }, [partners, roles]);

  const isAdmin = useMemo(() => {
    const adminRoles = ["Super Admin", "Admin"]; // Adjust based on your role names
    const currentUserRole =
      roles.find((role) => role.roleID === currentUser?.roleID)?.roleName || "";
    return adminRoles.includes(currentUserRole);
  }, [currentUser, roles]);

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const columns: CustomColumn<RaffleTransactionModel>[] = useMemo(
    () => [
      { id: "userPhoneNumber", label: "User" },
      {
        id: "amount",
        label: "Amount (NGN)",
        format: (value) => formatCurrency(value),
      },
      {
        id: "channelID",
        label: "Channel",
      },
      {
        id: "numberOfEntry",
        label: "Entries",
      },

      {
        id: "status",
        label: "Status",
        format: (value: string) => getStatusColor(value),
      },
      {
        id: "raffleDate",
        label: "Date",
        format: (value: string) => dayjs(value).format("YYYY-MM-DD"), // assuming the string is in a recognizable date format
      },
    ],
    [],
  );

  const getStatusColor = (value: string) => {
    const color =
      value.toLowerCase() === "successful" || value.toLowerCase() === "success"
        ? "green"
        : "red";
    return <Typography style={{ color: color }}>{value}</Typography>;
  };

  const filteredTransactions = useMemo(() => {
    let transactions = [...(raffleTransaction || [])]; // Create a shallow copy of the array

    if (selectedPartner !== "All") {
      const partner = partners.find((p) => p.staffID === selectedPartner);
      transactions = transactions.filter(
        (t) => t.businessID === partner?.businessID,
      );
    }

    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      transactions = transactions.filter(
        (t) =>
          t.userPhoneNumber.toLowerCase().includes(lowerCaseSearch) ||
          t.amount.toString().toLowerCase().includes(lowerCaseSearch) ||
          t.channelID.toLowerCase().includes(lowerCaseSearch),
      );
    }

    return transactions.sort((a, b) =>
      a.raffleDate < b.raffleDate
        ? sortDirection === "ascending"
          ? -1
          : 1
        : sortDirection === "ascending"
          ? 1
          : -1,
    );
  }, [raffleTransaction, search, selectedPartner, partners, sortDirection]);

  const successfulTransactions = useMemo(
    () =>
      filteredTransactions.filter(
        (t) =>
          t.status.toLowerCase() === "successful" ||
          t.status.toLowerCase() === "success",
      ),
    [filteredTransactions],
  );
  const otherTransactions = useMemo(
    () =>
      filteredTransactions.filter(
        (t) =>
          t.status.toLowerCase() !== "successful" ||
          t.status.toLowerCase() !== "success",
      ),
    [filteredTransactions],
  );

  const handlePartnerChange = (event: SelectChangeEvent<string>) => {
    setSelectedPartner(event.target.value);
  };

  if (
    isLoading ||
    !roles ||
    !currentUser ||
    !selectedPartner ||
    !raffleTransaction
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "ascending" ? "descending" : "ascending",
    );
  };

  // const handleUserClick = (raffle: RaffleTicketModel) => {
  //   navigate(`/dashboard/raffles/${raffle.raffleID}`);
  // };
  // const roleName = getRoleData(user?.roleID)?.roleName || "ReadOnlyPartner";

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Raffle Payments
            </Typography>
            <Button onClick={toggleSortDirection}>Toggle Date Sort</Button>
          </Box>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent={"flex-end"}
            sx={{ p: 2 }}
          >
            {isAdmin && (
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="partner-select-label">
                    Select Partner
                  </InputLabel>
                  <Select
                    labelId="partner-select-label"
                    id="partner-select"
                    value={selectedPartner}
                    onChange={handlePartnerChange}
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {filteredPartners.map((partner) => (
                      <MenuItem key={partner.staffID} value={partner.staffID}>
                        {partner.firstName} {partner.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Successful Payments" />
            <Tab label="Other Payments" />
          </Tabs>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {activeTab === 0 ? (
              successfulTransactions.length > 0 ? (
                <ReusableTable
                  data={successfulTransactions}
                  columns={columns}
                  paginationOptions={{
                    rowsPerPage: 20,
                    rowsPerPageOptions: [10, 20, 30],
                    showPagination: true,
                  }}
                  enableRowClick={true}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 200,
                    mt: 4,
                  }}
                >
                  <InfoIcon color="action" sx={{ fontSize: 60 }} />
                  <Typography variant="subtitle1" color="textSecondary">
                    No successful raffle payment.
                  </Typography>
                </Box>
              )
            ) : otherTransactions.length > 0 ? (
              <ReusableTable
                data={otherTransactions}
                columns={columns}
                paginationOptions={{
                  rowsPerPage: 20,
                  rowsPerPageOptions: [10, 20, 30],
                  showPagination: true,
                }}
                enableRowClick={true}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 200,
                  mt: 4,
                }}
              >
                <InfoIcon color="action" sx={{ fontSize: 60 }} />
                <Typography variant="subtitle1" color="textSecondary">
                  No other raffle payment.
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RafflePaymentPage;
