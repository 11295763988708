import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  RaffleWinnerCashOutPaymentModel,
  RaffleWinnerModel,
} from "../../../../@types/raffle";
import { useSelector } from "react-redux";

import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { Spin } from "antd";
import {
  payoutTransaction,
  raffleLoadingState,
  raffleWinners,
} from "../../../../selectors/raffleSelector";
import { Box, Button } from "@mui/material";
import RafflePayment from "./components/payment";
import WinnerForm from "./components/raffel-winner-form";
import PayoutStatus from "./components/payout-status";

const RaffleWinnerEdit = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const raffleWinnersData: RaffleWinnerModel[] | null =
    useSelector(raffleWinners);
  const payoutsTxtData: RaffleWinnerCashOutPaymentModel[] | null =
    useSelector(payoutTransaction);
  const isLoading: boolean = useSelector(raffleLoadingState);

  const user = useSelector(currentUserInfomation);
  const [winner, setWinnner] = useState<RaffleWinnerModel | null>(null);

  const [payout, setPayout] = useState<RaffleWinnerCashOutPaymentModel | null>(
    null,
  );

  useEffect(() => {
    // Simulating fetching user data by ID
    if (id && raffleWinnersData) {
      // Set initial selected bank
      const selectedStaff = raffleWinnersData.find(
        (raffle) => raffle.winnerID === id,
      );
      if (selectedStaff) {
        setWinnner(selectedStaff);
      }
    }
  }, [id, raffleWinnersData]);

  useEffect(() => {
    // Simulating fetching user data by ID
    if (id && payoutsTxtData) {
      // Set initial selected bank
      const data = payoutsTxtData.find((payout) => payout.winnerID === id);
      if (data) {
        setPayout(data);
      }
    }
  }, [id, payoutsTxtData]);

  if (!winner || !user || isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <Box mb={4}>
        <Button variant="contained" onClick={() => navigate(-1)} sx={{ mb: 2 }}>
          Go Back
        </Button>
      </Box>

      {payout && <PayoutStatus payout={payout} />}
      {!winner.paymentPaid && <RafflePayment winner={winner} />}
      <WinnerForm winner={winner} />
    </Box>
  );
};

export default RaffleWinnerEdit;
