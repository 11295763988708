import { AxiosResponse } from "axios";
import ApiService from "./api-service";
import { getUserIdFromToken } from "../utils/jwt-utils";
import {
  requestTokenToEmail,
  StaffModel,
  StaffResetModel,
} from "../@types/staff-model";

class StaffService extends ApiService {
  async registerStaff(payload: StaffModel): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/staff/create",
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      await this.invalidateCacheByKey("get/staff/getAll");
      return response.data;
    } catch (error) {
      console.error("Error registering staff:", error);
      throw error;
    }
  }
  async fetchAuthenticatedUserInfo(): Promise<any> {
    try {
      const staffID = getUserIdFromToken();
      if (!staffID) {
        throw new Error("No staffID found in the token");
      }
      const response: AxiosResponse = await this.axiosInstance.get(
        `/staff/getById/${staffID}`,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch authenticated user info:", error);
      throw error;
    }
  }

  async fetchStaffs(): Promise<any> {
    try {
      const response: AxiosResponse =
        await this.axiosInstance.get("/staff/getAll");
      return response.data;
    } catch (error) {
      console.error("Failed to fetch staff:", error);
      throw error;
    }
  }

  async changePassword(payload: Partial<StaffModel>): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/staff/change-password`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Failed to update password:", error);
      throw error;
    }
  }

  async sendResetPasswordToken(payload: requestTokenToEmail): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/staff/request-password-reset`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Failed to send password link:", error);
      throw error;
    }
  }

  async resetPassord(payload: StaffResetModel): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/staff/reset-password`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Failed to reset password:", error);
      throw error;
    }
  }

  async updateStaff(payload: Partial<StaffModel>): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/staff/update/${payload.staffID}`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      await this.invalidateCacheByKey(`get/staff/getById/${payload.staffID}`);
      await this.invalidateCacheByKey("get/staff/getAll");
      return response.data;
    } catch (error) {
      console.error("Failed to update staff data:", error);
      throw error;
    }
  }

  async updateStaffProfilePicture(payload: FormData): Promise<any> {
    const staffDataStr = payload.get("staffData") as string;
    const staffData = JSON.parse(staffDataStr);
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/staff/updatePicture`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      await this.invalidateCacheByKey(`get/staff/getById/${staffData.staffID}`);
      await this.invalidateCacheByKey("get/staff/getAll");
      return response.data;
    } catch (error) {
      console.error("Failed to update profile picture:", error);
      throw error;
    }
  }

  async deleteStaffById(staffID: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.delete(
        `/staff/delete/${staffID}`,
        {
          headers: { "Content-Type": this.getContentType(staffID) },
        },
      );
      await this.invalidateCacheByKey(`get/staff/getById/${staffID}`);
      await this.invalidateCacheByKey("get/staff/getAll");
      return response.data;
    } catch (error) {
      console.error("Failed to delete staff data:", error);
      throw error;
    }
  }
}

const staffService = new StaffService();
export default staffService;
