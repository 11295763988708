import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MonthlyCommunicationCost, UssdSmsState } from "../../@types/ussd-sms";
import { getAllUssdSmsCost } from "../thunks/sms-ussd-thunk";

const initialState: UssdSmsState = {
  allUssdSmsCost: [],
  status: "idle" as "idle" | "loading" | "succeeded" | "failed",
  error: null as string | null,
  loading: false,
  message: null,
};

const smsUssdSlice = createSlice({
  name: "sms-ussd",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUssdSmsCost.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        getAllUssdSmsCost.fulfilled,
        (state, action: PayloadAction<MonthlyCommunicationCost[]>) => {
          state.status = "succeeded";
          state.allUssdSmsCost = action.payload;
          state.error = null;
          state.loading = false;
        },
      )
      .addCase(getAllUssdSmsCost.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
  },
});

export default smsUssdSlice.reducer;
