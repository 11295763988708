import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/home";
import AdminRoute from "./admin-route";
import Dashboard from "./pages/admin/components/dashboard";
import AdminDashboard from "./pages/admin";
import { selectAuthenticationState } from "./selectors/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./store";
import { preloadDataAfterLogin } from "./store/thunks/preload";
import Users from "./pages/admin/components/users";
import Staffs from "./pages/admin/components/staff";
import UserEditForm from "./pages/admin/components/edit-users";
import Wallets from "./pages/admin/components/wallets";
import AddStaff from "./pages/admin/components/register-staff";
import BoxList from "./pages/admin/components/boxes";
import ProductList from "./pages/admin/components/products";
import EditBoxForm from "./pages/admin/components/edit-box";
import AddBox from "./pages/admin/components/add-box";
import PublicRoute from "./public-routes";
import EditProductForm from "./pages/admin/components/edit-product";
import AddProduct from "./pages/admin/components/add-product";
import EditWalletForm from "./pages/admin/components/edit-wallet";
import StaffEditForm from "./pages/admin/components/edit-staff";
import DepositList from "./pages/admin/components/transactions/deposit";
import BuyBackList from "./pages/admin/components/transactions/buy-back";
import CashOutList from "./pages/admin/components/transactions/cash-out";
import Profile from "./pages/admin/components/profile";
import GameSettings from "./pages/admin/components/game-settings";
import Winnnings from "./pages/admin/components/winnings";
import EditWinningForm from "./pages/admin/components/edit-winnings";
import VTUPurchaseList from "./pages/admin/components/vtu-purchases";
import RaffleTickets from "./pages/admin/components/raffle/raffle-tickets";
import RaffleWinnersPage from "./pages/admin/components/raffle/raffle-winners";
import RafflePaymentPage from "./pages/admin/components/raffle/raffle-payment";
import RaffleSettings from "./pages/admin/components/raffle/raffle-settings";
import RaffleDashboard from "./pages/admin/components/raffle/raffle-dashboard";
import RaffleWinnerEdit from "./pages/admin/components/raffle/raffle-winner-edit";
import RafflePayoutListPage from "./pages/admin/components/raffle/raffle-payouts";
import RequireRole from "./role-guard";
import ManualTriggers from "./pages/admin/components/maul-triggers";
import SendMessageForm from "./pages/admin/components/raffle/send-users-sms";
import AccessRoleList from "./pages/admin/components/roles-list";
import AddRole from "./pages/admin/components/add-access-roles";
import EditRole from "./pages/admin/components/edit-role";
import PartnerDashboard from "./pages/admin/components/partner-dashboard";
import LotteryDisplay from "./pages/admin/components/manual-lottery";
import UssdSms from "./pages/admin/components/raffle/ussd-sms";

const App: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticationState);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(preloadDataAfterLogin());
    }
  }, [dispatch, isAuthenticated]);
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Home />} />
        </Route>

        <Route element={<AdminRoute />}>
          <Route
            path="select-winners"
            element={
              <RequireRole
                allowedRoles={["Super Admin", "ReadOnlyPartner", "RafflePlay"]}
              >
                <LotteryDisplay />
              </RequireRole>
            }
          />
          <Route path="/dashboard" element={<AdminDashboard />}>
            <Route index element={<PartnerDashboard />} />
            <Route
              path="spin-dashboard"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <Dashboard />
                </RequireRole>
              }
            />
            <Route
              path="users"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <Users />
                </RequireRole>
              }
            />
            <Route
              path="staffs"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <Staffs />
                </RequireRole>
              }
            />
            <Route
              path="staffs/:id"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <StaffEditForm />
                </RequireRole>
              }
            />
            <Route
              path="box-list"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <BoxList />
                </RequireRole>
              }
            />
            <Route
              path="add-box"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <AddBox />
                </RequireRole>
              }
            />
            <Route
              path="add-role"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <AddRole />
                </RequireRole>
              }
            />
            <Route
              path="roles-list"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <AccessRoleList />
                </RequireRole>
              }
            />
            <Route
              path="roles-list/:roleId"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <EditRole />
                </RequireRole>
              }
            />
            <Route
              path="add-product"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <AddProduct />
                </RequireRole>
              }
            />
            <Route
              path="products"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <ProductList />
                </RequireRole>
              }
            />
            <Route
              path="users/:id"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <UserEditForm />
                </RequireRole>
              }
            />
            <Route
              path="box-list/:id"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <EditBoxForm />
                </RequireRole>
              }
            />
            <Route
              path="products/:id"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <EditProductForm />
                </RequireRole>
              }
            />
            <Route
              path="wallets"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <Wallets />
                </RequireRole>
              }
            />
            <Route
              path="wallets/:id"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <EditWalletForm />
                </RequireRole>
              }
            />
            <Route
              path="register-staff"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <AddStaff />
                </RequireRole>
              }
            />
            <Route
              path="deposit"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <DepositList />
                </RequireRole>
              }
            />
            <Route
              path="buy-back"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <BuyBackList />
                </RequireRole>
              }
            />
            <Route
              path="cash-out"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <CashOutList />
                </RequireRole>
              }
            />
            <Route path="account" element={<Profile />} />
            <Route
              path="game-settings"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <GameSettings />
                </RequireRole>
              }
            />
            <Route
              path="winnings"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <Winnnings />
                </RequireRole>
              }
            />
            <Route
              path="winnings/:id"
              element={
                <RequireRole allowedRoles={["Super Admin", "Admin"]}>
                  <EditWinningForm />
                </RequireRole>
              }
            />
            <Route
              path="vtu-purchases"
              element={
                <RequireRole allowedRoles={["Super Admin", "Admin"]}>
                  <VTUPurchaseList />
                </RequireRole>
              }
            />
            <Route
              path="raffle-tickets"
              element={
                <RequireRole
                  allowedRoles={[
                    "Super Admin",
                    "Admin",
                    "ReadOnlyPartner",
                    "RafflePlay",
                  ]}
                >
                  <RaffleTickets />
                </RequireRole>
              }
            />
            <Route path="raffle-winners" element={<RaffleWinnersPage />} />
            <Route
              path="raffle-winners/:id"
              element={
                <RequireRole allowedRoles={["Super Admin", "Admin"]}>
                  <RaffleWinnerEdit />
                </RequireRole>
              }
            />
            <Route
              path="raffle-transactions"
              element={
                <RequireRole
                  allowedRoles={["Super Admin", "Admin", "ReadOnlyPartner"]}
                >
                  <RafflePaymentPage />
                </RequireRole>
              }
            />
            <Route
              path="raffle-settings"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <RaffleSettings />
                </RequireRole>
              }
            />
            <Route
              path="send-users-messages"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <SendMessageForm />
                </RequireRole>
              }
            />
            <Route
              path="sms-ussd"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <UssdSms />
                </RequireRole>
              }
            />
            <Route
              path="raffle-dashboard"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <RaffleDashboard />
                </RequireRole>
              }
            />
            <Route
              path="raffle-payouts"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <RafflePayoutListPage />
                </RequireRole>
              }
            />
            <Route
              path="manual-triggers"
              element={
                <RequireRole allowedRoles={["Super Admin"]}>
                  <ManualTriggers />
                </RequireRole>
              }
            />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
