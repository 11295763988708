import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import { useNavigate } from "react-router-dom";
import {
  raffleLoadingState,
  raffleSettingsData,
} from "../../../../selectors/raffleSelector";
import { Formik, Form, FormikProps, FieldArray } from "formik";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { Grid, Paper, Typography, TextField, Box, Button } from "@mui/material";
import { Spin } from "antd";
import { RaffleSettingsModel } from "../../../../@types/raffle";
import logging from "../../../../logging";
import {
  getRaffleSettingsData,
  updateRaffleSettingsData,
} from "../../../../store/thunks/raffle-thunk";
import { formatCurrency } from "../../../../utils";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  monthlyWinPrice: Yup.number().required("Monthly win price is required."),
  numberOfMonthlyWinner: Yup.number().required(
    "Number of monthly winners is required.",
  ),
  raffleChannels: Yup.array().of(
    Yup.object().shape({
      channelID: Yup.string().required("Channel ID is required."),
      entryPrice: Yup.number().required("Entry price is required."),
      label: Yup.string().required("Label is required."),
      amount: Yup.number().required("Amount is required."),
      numberOfWinners: Yup.number().required("Number of winners is required."),
    }),
  ),
  raffleTimes: Yup.array().of(
    Yup.string().required("Raffle time is required."),
  ),
  raffleMaintenance: Yup.boolean().required("Raffle maintenance is required."),
  raffleOutOfOrder: Yup.boolean().required(
    "Raffle out of order status is required.",
  ),
});

const RaffleSettings = () => {
  const isLoading = useSelector(raffleLoadingState);
  const raffleSettings = useSelector(raffleSettingsData);
  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);

  const [editMode, setEditMode] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const formikRef = useRef<FormikProps<any>>(null);

  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };

  useEffect(() => {
    // Ensure that both user and roles are fully loaded
    if (user && roles.length > 0) {
      const roleData = getRoleData(user.roleID);
      // Redirect if the user is not a Super Admin or Admin
      if (
        roleData &&
        roleData.roleName !== "Super Admin" &&
        roleData.roleName !== "Admin"
      ) {
        navigate("/dashboard");
      }
    }
  }, [user, roles, navigate]);

  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  const handleSubmit = async (values: RaffleSettingsModel, actions: any) => {
    const updatedValues = {
      ...values,
      monthlyWinPrice: parseFloat(values.monthlyWinPrice.toString()),
      numberOfMonthlyWinner: parseInt(
        values.numberOfMonthlyWinner.toString(),
        10,
      ),
      raffleChannels: values.raffleChannels.map((channel) => ({
        ...channel,
        entryPrice: parseFloat(channel.entryPrice.toString()),
        amount: parseFloat(channel.amount.toString()),
        numberOfWinners: parseInt(channel.numberOfWinners.toString(), 10),
      })),
    };

    try {
      const resultAction = await dispatch(
        updateRaffleSettingsData(updatedValues),
      );
      if (updateRaffleSettingsData.fulfilled.match(resultAction)) {
        await dispatch(getRaffleSettingsData());
        logging.info("Settings data updated successful!");
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  if (!raffleSettings || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const initialValues: RaffleSettingsModel = {
    raffleSettingID: raffleSettings.raffleSettingID ?? "",
    raffleChannels: raffleSettings.raffleChannels ?? [],
    raffleTimes: raffleSettings.raffleTimes ?? [],
    monthlyWinPrice: raffleSettings.monthlyWinPrice ?? 0,
    numberOfMonthlyWinner: raffleSettings.numberOfMonthlyWinner ?? 0,
    raffleMaintenance: raffleSettings.raffleMaintenance ?? false,
    raffleOutOfOrder: raffleSettings.raffleOutOfOrder ?? false,
    isMonthRaffleEnabled: raffleSettings.isMonthRaffleEnabled ?? false,
    raffleMaintenanceMessage: raffleSettings.raffleMaintenanceMessage ?? "",
    isTimeBasedDraw: raffleSettings.isTimeBasedDraw ?? false,
    blacklist: raffleSettings.blacklist ?? [],
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Box>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Raffle Settings
                </Typography>
              </Box>
            </Grid>
            {getRoleData(user.roleID)?.roleName === "Super Admin" && (
              <Box display="flex" justifyContent="flex-end" mb={3}>
                {editMode ? (
                  <>
                    <Button
                      sx={{ marginRight: 2 }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save Changes
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={exitEditMode}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={enterEditMode}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            )}
            <Grid container spacing={2}>
              <Paper elevation={2} sx={{ p: 4, width: "100%" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Raffle Settings:
                    </Typography>
                  </Grid>
                  <FieldArray name="raffleChannels">
                    {({ push, remove }) => (
                      <Grid item>
                        {values.raffleChannels.map((channel, index) => (
                          <Grid item xs={12} key={index}>
                            {editMode ? (
                              <Box mb={2}>
                                <TextField
                                  label="Channel Label"
                                  name={`raffleChannels.${index}.label`}
                                  value={channel.label}
                                  onChange={handleChange}
                                  fullWidth
                                  sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                  label="Entry Price"
                                  name={`raffleChannels.${index}.entryPrice`}
                                  value={channel.entryPrice}
                                  onChange={handleChange}
                                  fullWidth
                                  sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                  label="Winning Amount"
                                  name={`raffleChannels.${index}.amount`}
                                  value={channel.amount}
                                  onChange={handleChange}
                                  fullWidth
                                  sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                  label="Number of Winners"
                                  name={`raffleChannels.${index}.numberOfWinners`}
                                  value={channel.numberOfWinners}
                                  onChange={handleChange}
                                  fullWidth
                                  sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                  label="Business ID"
                                  name={`raffleChannels.${index}.businessID`}
                                  value={channel.businessID}
                                  onChange={handleChange}
                                  fullWidth
                                  sx={{ marginBottom: 2 }}
                                />
                                <Button
                                  onClick={() => remove(index)}
                                  color="error"
                                >
                                  Remove
                                </Button>
                              </Box>
                            ) : (
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="primary"
                                  sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                                >
                                  {`${channel.label}`}
                                </Typography>

                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 700, fontSize: 18, mb: 3 }}
                                >
                                  {`Price: ${channel.amount}`}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 700, fontSize: 18, mb: 3 }}
                                >
                                  {`Number of Winners: ${channel.numberOfWinners}`}
                                </Typography>

                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 700, fontSize: 18, mb: 3 }}
                                >
                                  {`Channel ID: ${channel.channelID}`}
                                </Typography>

                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 700, fontSize: 18, mb: 3 }}
                                >
                                  {`Business ID: ${channel.businessID}`}
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                        ))}
                        {editMode && (
                          <Button
                            onClick={() =>
                              push({
                                channelId: "",
                                label: "",
                                entryPrice: 0,
                                amount: 0,
                                numberOfWinners: 0,
                              })
                            }
                            color="primary"
                            variant="contained"
                          >
                            Add Channel
                          </Button>
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Raffle Times:
                    </Typography>
                  </Grid>
                  <FieldArray name="raffleTimes">
                    {({ push, remove }) => (
                      <Grid item xs={12}>
                        {values.raffleTimes.map((time, index) => (
                          <Box key={index} mb={2}>
                            {editMode ? (
                              <>
                                <TextField
                                  label={`Time ${index + 1}`}
                                  name={`raffleTimes.${index}`}
                                  value={time}
                                  onChange={handleChange}
                                  fullWidth
                                  sx={{ marginBottom: 2 }}
                                />
                                <Button
                                  onClick={() => remove(index)}
                                  color="error"
                                >
                                  Remove
                                </Button>
                              </>
                            ) : (
                              <Typography
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                              >
                                {time}
                              </Typography>
                            )}
                          </Box>
                        ))}
                        {editMode && (
                          <Button
                            onClick={() => push("")}
                            color="primary"
                            variant="contained"
                          >
                            Add Time
                          </Button>
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Blacklisted Users:
                    </Typography>
                  </Grid>
                  <FieldArray name="blacklist">
                    {({ push, remove }) => (
                      <Grid item xs={12}>
                        {values.blacklist.map((user, index) => (
                          <Box key={index} mb={2}>
                            {editMode ? (
                              <>
                                <TextField
                                  label={`User ${index + 1}`}
                                  name={`blacklist.${index}`}
                                  value={user}
                                  onChange={handleChange}
                                  fullWidth
                                  sx={{ marginBottom: 2 }}
                                />
                                <Button
                                  onClick={() => remove(index)}
                                  color="error"
                                >
                                  Remove
                                </Button>
                              </>
                            ) : (
                              <Box display="flex" justifyContent="flex-end">
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: 600, fontSize: 17, mb: 1 }}
                                >
                                  {user}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        ))}
                        {editMode && (
                          <Button
                            onClick={() => push("")}
                            color="primary"
                            variant="contained"
                          >
                            Add User
                          </Button>
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Monthly Win Price:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Monthly Price"
                        name="monthlyWinPrice"
                        margin="normal"
                        value={values.monthlyWinPrice}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={
                          touched.monthlyWinPrice && !!errors.monthlyWinPrice
                        }
                        helperText={
                          touched.monthlyWinPrice &&
                          typeof errors.monthlyWinPrice === "string"
                            ? errors.monthlyWinPrice
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {formatCurrency(raffleSettings.monthlyWinPrice, true)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Number Of Monthly Winners:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="No. of Monthly Winners"
                        name="numberOfMonthlyWinner"
                        margin="normal"
                        value={values.numberOfMonthlyWinner}
                        inputProps={{ readOnly: editMode ? false : true }}
                        error={
                          touched.numberOfMonthlyWinner &&
                          !!errors.numberOfMonthlyWinner
                        }
                        helperText={
                          touched.numberOfMonthlyWinner &&
                          typeof errors.numberOfMonthlyWinner === "string"
                            ? errors.numberOfMonthlyWinner
                            : ""
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 900, fontSize: 22, mb: 3 }}
                      >
                        {raffleSettings?.numberOfMonthlyWinner}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Raffle Maintenance:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="Raffle Maintenance"
                        name="raffleMaintenance"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        value={values.raffleMaintenance ? "yes" : "no"}
                        onChange={(event) => {
                          const value = event.target.value === "yes"; // Convert back to boolean
                          setFieldValue("raffleMaintenance", value); // Manually update the field value
                        }}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </TextField>
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 22, mb: 3 }}
                      >
                        {raffleSettings?.raffleMaintenance ? "Yes" : "No"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Maintenance Message:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} mt={2}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Maintenance Message"
                      name="raffleMaintenanceMessage"
                      variant="outlined"
                      value={values.raffleMaintenanceMessage}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !editMode,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Raffle Out Of Order:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="raffle Out Of Order"
                        name="raffleOutOfOrder"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        value={values.raffleOutOfOrder ? "yes" : "no"}
                        onChange={(event) => {
                          const value = event.target.value === "yes"; // Convert back to boolean
                          setFieldValue("raffleOutOfOrder", value); // Manually update the field value
                        }}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </TextField>
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 22, mb: 3 }}
                      >
                        {raffleSettings?.raffleOutOfOrder ? "Yes" : "No"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Is Monthly Raffle Enable:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="raffle Out Of Order"
                        name="isMonthRaffleEnabled"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        value={values.isMonthRaffleEnabled ? "yes" : "no"}
                        onChange={(event) => {
                          const value = event.target.value === "yes"; // Convert back to boolean
                          setFieldValue("isMonthRaffleEnabled", value); // Manually update the field value
                        }}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </TextField>
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 22, mb: 3 }}
                      >
                        {raffleSettings?.isMonthRaffleEnabled ? "Yes" : "No"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Is It Time Based Draws:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <TextField
                        fullWidth
                        label="raffle Out Of Order"
                        name="isTimeBasedDraw"
                        margin="normal"
                        select
                        SelectProps={{
                          native: true,
                        }}
                        value={values.isTimeBasedDraw ? "yes" : "no"}
                        onChange={(event) => {
                          const value = event.target.value === "yes"; // Convert back to boolean
                          setFieldValue("isTimeBasedDraw", value); // Manually update the field value
                        }}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </TextField>
                    ) : (
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 600, fontSize: 22, mb: 3 }}
                      >
                        {raffleSettings?.isTimeBasedDraw ? "Yes" : "No"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default RaffleSettings;
