import { AxiosResponse } from "axios";
import ApiService from "./api-service";
import { Products, UpdateProductWonRequest } from "../@types/products-model";
import localforage from "localforage";

class ProductService extends ApiService {
  async fetchProducts(): Promise<any> {
    try {
      const currentVersion = await this.getVersions();
      const cacheKey = `get/box/get-all-packages`;
      const cachedData = await localforage.getItem<Products>(cacheKey);
      if (cachedData && cachedData.version === currentVersion.packageVersion) {
        return cachedData.products;
      } else {
        // Fetch new data if the version doesn't match
        const response: AxiosResponse = await this.axiosInstance.get(
          "/products/get-all-products",
        );
        const dataToCache = {
          version: currentVersion,
          products: response.data.products,
        };
        await localforage.setItem(cacheKey, dataToCache);
        return response.data;
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
      throw error;
    }
  }

  async createProducts(data: FormData): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/products/create-product",
        data,
        {
          headers: { "Content-Type": this.getContentType(data) },
        },
      );
      await this.invalidateCacheByKey("get/products/get-all-products");
      return response.data;
    } catch (error) {
      console.error("Error creating  products:", error);
      throw error;
    }
  }

  async updateProducts(data: FormData): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        "/products/update-product",
        data,
        {
          headers: { "Content-Type": this.getContentType(data) },
        },
      );
      await this.invalidateCacheByKey("get/products/get-all-products");
      return response.data;
    } catch (error) {
      console.error("Error updating  product:", error);
      throw error;
    }
  }

  async deleteProduct(productID: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/products/delete-product/${productID}`,
      );
      await this.invalidateCacheByKey("get/products/get-all-products");
      return response.data;
    } catch (error) {
      console.error("Error deleting  products:", error);
      throw error;
    }
  }

  async fetchAllUserProductsHistory(): Promise<any> {
    try {
      const response: AxiosResponse = await this.getNonCachingInstance().get(
        "/history/get-all-history",
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch products history:", error);
      throw error;
    }
  }

  async fetchAllVTUPurchases(): Promise<any> {
    try {
      const response: AxiosResponse = await this.getNonCachingInstance().get(
        "/airtime/get-all-vtu-purchase",
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch all puchased vtu:", error);
      throw error;
    }
  }

  async getVtuAggregatedAmounts(): Promise<any> {
    try {
      const response: AxiosResponse = await this.getNonCachingInstance().get(
        `/airtime/get-aggregated-vtu-purchases`,
      );

      return response.data;
    } catch (error) {
      console.error("Failed to fetch all aggregated vtu purchases:", error);
      throw error;
    }
  }
  async UpdateWonProduct(payload: UpdateProductWonRequest): Promise<any> {
    try {
      // Append query parameters to the URL
      const url = `/history/admin-history-update`;

      const response: AxiosResponse = await this.getNonCachingInstance().put(
        url,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      const result = response.data;

      return result;
    } catch (error) {
      console.error("Error logging in won product:", error);
      throw error;
    }
  }

  async getVersions(): Promise<any> {
    try {
      const response = await this.getNonCachingInstance().get(
        `/versions/get-versions`,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch versions:", error);
      throw error;
    }
  }
}

const productService = new ProductService();
export default productService;
