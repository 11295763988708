import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";

const FeeInfoAlert: React.FC = () => {
  return (
    <Alert severity="info">
      <AlertTitle>Fee Information</AlertTitle>
      <Typography variant="body2">
        All the transactions shown reflect all the charges and fixed rates from
        us. Please bear in mind that our third-party service provider, Paystack,
        has their own fee structure:
      </Typography>
      <Typography variant="body2" component="div">
        <strong>Paystack Fees:</strong>
        <ul>
          <li>
            1.5% + NGN 100 (applies to all local channels, including Direct
            Debit).
          </li>
          <li>All local transaction fees are capped at NGN 2,000.</li>
          <li>The NGN 100 fee is waived for transactions under NGN 2,500.</li>
        </ul>
      </Typography>
      <Typography variant="body2">
        These details are important to consider when reviewing transaction
        costs.
      </Typography>
      <Typography variant="body2" component="div">
        <strong>Net Profit:</strong>
      </Typography>
      <Typography variant="body2">
        The net profit to be shared between partners is calculated after
        deducting costs associated with Winners Payout, USSD and SMS charges,
        and the fees charged by the payment gateway, Paystack. This ensures that
        the amount distributed reflects the actual earnings post all necessary
        expenditures.
      </Typography>
    </Alert>
  );
};

export default FeeInfoAlert;
