import React, { useState } from "react";
import { Typography, Box, useTheme, BoxProps } from "@mui/material";
import { NumberListProps } from "../../../../../@types/selection-model";
import { motion } from "framer-motion";
import { styled } from "@mui/system";

const StickyHeader = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  background: "rgba(0, 0, 0, 0.8)",
  zIndex: 10,
  padding: theme.spacing(2),
  textAlign: "center",
}));

const NumberBall = styled(motion.div)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer", // Add pointer to indicate it's clickable
  transition: "background-color 0.3s",
}));

const NumberListSection: React.FC<NumberListProps> = ({ tickets }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        overflowY: "auto",
        height: "100%",
        width: "250px",
        background: "rgba(0, 0, 0, 0.8)",
        padding: "0 8px",
        boxSizing: "border-box",
      }}
    >
      <StickyHeader>
        <Typography
          variant="h6"
          color="paleturquoise"
          sx={{ textAlign: "center" }}
        >
          Raffle Numbers
        </Typography>
      </StickyHeader>

      {tickets.length > 0 ? (
        tickets.map((ticket, index) => (
          <NumberBall
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Typography
              sx={{
                padding: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
                background: "transparent",
                color: "grey",
                transition: "all 0.3s",
              }}
            >
              {ticket.userPhoneNumber}
            </Typography>
          </NumberBall>
        ))
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ color: "white", textAlign: "center" }}
          >
            No tickets available. Please fetch tickets to proceed.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NumberListSection;
