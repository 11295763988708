import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse, PayloadError } from "../../@types/common";
import logging from "../../logging";
import {
  MonthlyCommunicationCost,
  UpdateMonthlyCostRequest,
} from "../../@types/ussd-sms";
import ussdSmsService from "../../services/sms-ussd.service";

export const getAllUssdSmsCost = createAsyncThunk<
  MonthlyCommunicationCost[],
  void
>("ussdSms/getAllUssdSmsCost", async (_, thunkAPI) => {
  try {
    return await ussdSmsService.fetchAllUssdSmsCost();
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Failed to fetch all ussd-sms cost:", serverErrorMessage);
    return thunkAPI.rejectWithValue({ message: serverErrorMessage });
  }
});

export const createUssdSmsCost = createAsyncThunk<
  any,
  MonthlyCommunicationCost,
  { rejectValue: string }
>(
  "ussdSms/createUssdSmsCost",
  async (data: MonthlyCommunicationCost, thunkAPI) => {
    try {
      return await ussdSmsService.createNewUssdSmsCost(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error creating monthly cost:", serverErrorMessage);
      return thunkAPI.rejectWithValue(serverErrorMessage);
    }
  },
);

export const updateUssdSmsCost = createAsyncThunk<
  any,
  UpdateMonthlyCostRequest,
  { rejectValue: string }
>(
  "ussdSms/updateUssdSmsCost",
  async (requestData: UpdateMonthlyCostRequest, thunkAPI) => {
    try {
      return await ussdSmsService.updateExistingUssdSmsCost(requestData);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error updating monthly cost:", serverErrorMessage);
      return thunkAPI.rejectWithValue(serverErrorMessage);
    }
  },
);
