import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { setupCache } from "axios-cache-adapter";
import localforage from "localforage";
import Cookies from "js-cookie";

const CAHCE_EXPIRATION = 60 * 1; // Cache for 5 minutes

const cache = setupCache({
  maxAge: CAHCE_EXPIRATION * 1000,
  store: localforage,
  exclude: {
    query: false,
  },
  key: (req) => {
    // Use the method + URL as the cache key.
    // You can also include any other dynamic parameters here if needed.
    return (req.method || "GET") + (req.url || ""); // Defaults added
  },
});

class ApiService {
  private static instance: ApiService;
  protected axiosInstance: AxiosInstance;
  protected nonCachingAxiosInstance: AxiosInstance;

  constructor(baseURL: string = "https://api.ko-kobox.net/admin/api") {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
      adapter: cache.adapter,
    });

    this.nonCachingAxiosInstance = axios.create({
      baseURL: baseURL,
    });
    // Setup interceptors for both instances
    this.setupInterceptors(this.axiosInstance);
    this.setupInterceptors(this.nonCachingAxiosInstance);
  }

  // Setup interceptors
  private setupInterceptors(axiosInstance: AxiosInstance) {
    // Request interceptor
    axiosInstance.interceptors.request.use(
      (config) => {
        const token = Cookies.get("authToken");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // Response interceptor (optional)
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          // Handle token refresh logic here if your API supports it.
          // Fetch a new token and update `authToken` in localStorage

          // Retry the original request
          const token = Cookies.get("authToken");
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return this.axiosInstance(originalRequest);
        }
        return Promise.reject(error);
      },
    );
  }
  // Utility function to remove a specific cache by key
  async invalidateCacheByKey(key: string): Promise<void> {
    try {
      localforage
        .keys()
        .then((keys) => {
          // An array of all the keys in the datastore.
          console.log(keys);
        })
        .catch((err) => {
          // This code runs if there were any errors.
          console.error(err);
        });
      await localforage.removeItem(key);
    } catch (error) {
      console.error(`Failed to invalidate cache for key: ${key}`, error);
    }
  }

  getNonCachingInstance() {
    return this.nonCachingAxiosInstance;
  }

  // Utility to determine the correct Content-Type header
  getContentType(data: any): string {
    return data instanceof FormData
      ? "multipart/form-data"
      : "application/json";
  }

  // Get the singleton instance
  static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }
  // Method to fetch data with optional force update
  async fetchData(
    endpoint: string,
    forceUpdate = false,
    options?: AxiosRequestConfig,
  ): Promise<any> {
    const axiosInstance = forceUpdate
      ? this.nonCachingAxiosInstance
      : this.axiosInstance;
    return axiosInstance
      .get(endpoint, options)
      .then((response) => response.data)
      .catch((error) => {
        // Handle errors appropriately
        console.error("Fetch data error:", error);
        throw error;
      });
  }
  async postData(
    endpoint: string,
    data?: any,
    options?: AxiosRequestConfig,
    forceUpdate = false,
  ): Promise<any> {
    if (forceUpdate) {
      await this.invalidateCacheByKey(endpoint);
    }

    const axiosInstance = forceUpdate
      ? this.nonCachingAxiosInstance
      : this.axiosInstance;
    return axiosInstance
      .post(endpoint, data, options)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Post data error:", error);
        throw error; // Rethrow to allow caller to handle
      });
  }
}

export default ApiService;
