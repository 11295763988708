import { AxiosResponse } from "axios";
import ApiService from "./api-service";
import {
  RaffleSettingsModel,
  RaffleWinnerModel,
  RaffleWinnerPaymentRequest,
  SendMessageRequest,
} from "../@types/raffle";
import { RaffleRequestPayload } from "../@types/selection-model";

class RaffleService extends ApiService {
  async fetchAllRaffleTickets(): Promise<any> {
    try {
      const url = `/raffle-ticket/get-all-ticket`;
      const response: AxiosResponse =
        await this.nonCachingAxiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch all raffle tickets:", error);
      throw error;
    }
  }

  async fetchAllRaffleWinners(): Promise<any> {
    try {
      const url = `/raffle-winners/get-all-winners`;
      const response: AxiosResponse =
        await this.nonCachingAxiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch all raffle winners:", error);
      throw error;
    }
  }

  async fetchPast3DaysRaffleWinners(): Promise<any> {
    try {
      const url = "/raffle-winners/get-winners-last-3-days";
      const response: AxiosResponse =
        await this.nonCachingAxiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch all raffle winners:", error);
      throw error;
    }
  }

  async fetchDynamicRaffle(payload: RaffleRequestPayload): Promise<any> {
    try {
      const url = "/manual-raffle-tickets-fetch";
      const response: AxiosResponse = await this.nonCachingAxiosInstance.post(
        url,
        payload,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch today's raffle ticket:", error);
      throw error;
    }
  }
  async selectManualWinner(payload: RaffleRequestPayload): Promise<any> {
    try {
      const url = "/manual-raffle-dialy-selection";
      const response: AxiosResponse = await this.nonCachingAxiosInstance.post(
        url,
        payload,
      );
      return response.data;
    } catch (error) {
      console.error("Failed to select winners:", error);
      throw error;
    }
  }

  async fetchAllWinnersPayoutTransactions(): Promise<any> {
    try {
      const url = `/raffle-payout/get-all-payouts`;
      const response: AxiosResponse =
        await this.nonCachingAxiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch all winners payout:", error);
      throw error;
    }
  }

  async fetchAllRaffleTransactions(): Promise<any> {
    try {
      const url = `/raffle-transaction/get-all-transactions`;
      const response: AxiosResponse =
        await this.nonCachingAxiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch all raffle tickets:", error);
      throw error;
    }
  }

  async getRaffleSettings(): Promise<any> {
    try {
      const url = `/raffle-settings/get-settings`;
      const response: AxiosResponse = await this.axiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error("Failed to get the raffle settings:", error);
      throw error;
    }
  }

  async updateRaffleSettings(payload: RaffleSettingsModel): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/raffle-settings/update-settings`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      await this.invalidateCacheByKey(`get/raffle-settings/get-settings`);
      return response.data;
    } catch (error) {
      console.error("Failed to update raffles settings data:", error);
      throw error;
    }
  }

  async updateRaffleWinners(payload: RaffleWinnerModel): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/raffle-winners/update-winner-data`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      await this.invalidateCacheByKey(`get/raffle-winners/get-all-winners`);
      return response.data;
    } catch (error) {
      console.error("Failed to update raffles settings data:", error);
      throw error;
    }
  }

  async InitiateWinnerPayoutCheckout(
    payload: RaffleWinnerPaymentRequest,
  ): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        `/raffle-winner-payment/winners-payout`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      await this.invalidateCacheByKey(`get/raffle-winners/get-all-winners`);
      await this.invalidateCacheByKey(`get/raffle-payout/get-all-payouts`);

      return response.data;
    } catch (error) {
      console.error("Failed to update raffles settings data:", error);
      throw error;
    }
  }

  async monthlyWinnerTrigger(adminSecret: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        `/raffle-monthly-winner/${adminSecret}`,
      );
      await this.invalidateCacheByKey(`get/raffle-winners/get-all-winners`);

      return response.data;
    } catch (error) {
      console.error("Failed to trigger monthly winner manually:", error);
      throw error;
    }
  }
  async dailyWinnerTrigger(adminSecret: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        `/raffle-dialy-winner/${adminSecret}`,
      );
      await this.invalidateCacheByKey(`get/raffle-winners/get-all-winners`);

      return response.data;
    } catch (error) {
      console.error("Failed to trigger monthly winner manually:", error);
      throw error;
    }
  }

  async manualUpdateAndCreateTicket(reference: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        `/manual-ticket-purchase/${reference}`,
      );
      await this.invalidateCacheByKey(`get/raffle-ticket/get-all-ticket`);

      return response.data;
    } catch (error) {
      console.error("Failed to trigger monthly winner manually:", error);
      throw error;
    }
  }

  async sendParticipantMessages(payload: SendMessageRequest): Promise<any> {
    try {
      const response: AxiosResponse = await this.nonCachingAxiosInstance.post(
        `/raffle-sms/send-users-sms`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Failed to sned messages to users:", error);
      throw error;
    }
  }
}
const raffleService = new RaffleService();
export default raffleService;
