import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  TextField,
  Box,
  Button,
  Modal,
  Fade,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Importing Close icon
import {
  Formik,
  Form,
  FormikHelpers,
  FieldArray,
  useField,
  FieldHookConfig,
} from "formik";
import * as Yup from "yup";
import {
  DailyCost,
  MonthlyCommunicationCost,
  UpdateMonthlyCostRequest,
} from "../../../../@types/ussd-sms";
import dayjs from "dayjs";
import { CustomColumn } from "../../../../@types/table";
import { useDispatch, useSelector } from "react-redux";
import {
  ussdSmsSelectorLoadingState,
  allUssdSmsCostData,
} from "../../../../selectors/ussdSmsSelectors";
import ReusableTable from "../../../../components/reusable-table";
import { Spin } from "antd";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import logging from "../../../../logging";
import { AppDispatch } from "../../../../store";
import {
  createUssdSmsCost,
  getAllUssdSmsCost,
  updateUssdSmsCost,
} from "../../../../store/thunks/sms-ussd-thunk";
import { formatCurrency } from "../../../../utils";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { staffSelectorData } from "../../../../selectors/staffSelector";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { v4 as uuidv4 } from "uuid";

// Validation schema
const validationSchema = Yup.object().shape({
  businessID: Yup.string().required("Business ID is required"),
  monthStart: Yup.date().required("Month start date is required"),
  dailyCosts: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().required("Date is required"),
      smsCost: Yup.number()
        .required("SMS cost is required")
        .min(0, "Cost cannot be negative"),
      ussdCost: Yup.number()
        .required("USSD cost is required")
        .min(0, "Cost cannot be negative"),
    }),
  ),
});

type FormikTextFieldProps = FieldHookConfig<string> & {
  label: string;
  type?: string;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const UssdSms: React.FC = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();

  const isLoading = useSelector(ussdSmsSelectorLoadingState);
  const allSmsUssdCost = useSelector(allUssdSmsCostData);
  const roles = useSelector(rolesSelectorList);
  const currentUser = useSelector(currentUserInfomation);
  const partners = useSelector(staffSelectorData);
  const search = useSelector(searchQuery);

  const [openModal, setOpenModal] = useState(false);
  const [selectedCost, setSelectedCost] =
    useState<MonthlyCommunicationCost | null>(null);
  const [actionState, setActionState] = useState("create");
  const [selectedPartner, setSelectedPartner] = useState<string>("");
  const [sortDirection, setSortDirection] = useState("descending");

  useEffect(() => {
    if (currentUser && roles) {
      const userRole = roles.find((role) => role.roleID === currentUser.roleID);
      if (userRole?.roleName === "ReadOnlyPartner") {
        setSelectedPartner(currentUser.staffID); // Set the current user as the selected partner if they are a ReadOnlyPartner
      } else {
        setSelectedPartner("All"); // Set to 'All' if the current user is not a ReadOnlyPartner
      }
    }
  }, [currentUser, roles]);

  const filteredSmsUssdMonthlyCost = useMemo(() => {
    let allMonthlyCost = [...(allSmsUssdCost || [])]; // Create a shallow copy of the array

    if (selectedPartner !== "All") {
      const partner = partners.find((p) => p.staffID === selectedPartner);
      allMonthlyCost = allMonthlyCost.filter(
        (monthlCost) => monthlCost.businessID === partner?.businessID,
      );
    }

    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      allMonthlyCost = allMonthlyCost.filter((monthlyCost) => {
        const matchesTopLevel =
          monthlyCost.businessID.toLowerCase().includes(lowerCaseSearch) ||
          monthlyCost.monthStart.toLowerCase().includes(lowerCaseSearch);

        const matchesDailyCosts = monthlyCost.dailyCosts.some((daily) =>
          Object.values(daily).some((val) =>
            val?.toString().toLowerCase().includes(lowerCaseSearch),
          ),
        );

        return matchesTopLevel || matchesDailyCosts;
      });
    }

    return allMonthlyCost.sort((a, b) =>
      a.monthStart < b.monthStart
        ? sortDirection === "ascending"
          ? -1
          : 1
        : sortDirection === "ascending"
          ? 1
          : -1,
    );
  }, [allSmsUssdCost, selectedPartner, partners, search, sortDirection]);

  const handleRowClick = (item: MonthlyCommunicationCost) => {
    setSelectedCost(item);
    handleOpen("edit");
  };

  const handleOpen = (action: string) => {
    setActionState(action);
    setOpenModal(true);
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.setAttribute("aria-hidden", "true");
    }
    const modal = document.getElementById("modal");
    if (modal) {
      modal.removeAttribute("aria-hidden");
      modal.focus();
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setActionState("create");
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.removeAttribute("aria-hidden");
    }
    const triggerButton = document.getElementById("triggerButton");
    if (triggerButton) {
      triggerButton.focus();
    }
  };

  const FormikTextField: React.FC<FormikTextFieldProps> = ({
    name,
    label,
    type,
    readOnly = false,
    onChange,
  }) => {
    const [field, meta] = useField(name);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // Call the Formik's internal handle change
      field.onChange(e);

      // Call external onChange if it's provided
      if (onChange) {
        onChange(e);
      }
    };
    return (
      <TextField
        {...field}
        type={type}
        label={label}
        fullWidth
        InputProps={{ readOnly: readOnly }}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        onChange={handleChange}
      />
    );
  };

  const handleSubmit = async (
    values: MonthlyCommunicationCost,
    { setSubmitting }: FormikHelpers<MonthlyCommunicationCost>,
  ) => {
    const formattedValues = {
      ...values,
      monthStart: new Date(values.monthStart).toISOString(),
      dailyCosts: values.dailyCosts.map((cost) => ({
        ...cost,
        date: new Date(cost.date).toISOString(),
      })),
    };

    try {
      let resultAction;
      if (actionState === "edit" && selectedCost) {
        const updatePayload: UpdateMonthlyCostRequest = {
          businessID: selectedCost.businessID,
          monthStart: selectedCost.monthStart,
          updates: {
            dailyCosts: formattedValues.dailyCosts,
          },
        };
        resultAction = await dispatch(updateUssdSmsCost(updatePayload));
        // Check if the update action was fulfilled
        if (updateUssdSmsCost.fulfilled.match(resultAction)) {
          dispatch(getAllUssdSmsCost());
          logging.info("Update successful!");
        } else if (updateUssdSmsCost.rejected.match(resultAction)) {
          const errorMessage =
            resultAction.error?.message || "Failed to update cost data";
          logging.error(errorMessage);
        }
      } else {
        // Proceed with creating new cost
        resultAction = await dispatch(createUssdSmsCost(formattedValues));
        // Check if the create action was fulfilled
        if (createUssdSmsCost.fulfilled.match(resultAction)) {
          dispatch(getAllUssdSmsCost());
          logging.info("Creation successful!");
        } else if (createUssdSmsCost.rejected.match(resultAction)) {
          const errorMessage =
            resultAction.error?.message || "Failed to create cost data";
          logging.error(errorMessage);
        }
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      setSubmitting(false);
      handleClose();
    }
  };

  const columns: CustomColumn<MonthlyCommunicationCost>[] = useMemo(
    () => [
      { id: "businessID", label: "Business ID" },
      {
        id: "drawDate",
        label: "Date",
        format: (value: string) => dayjs(value).format("YYYY-MM-DD"), // assuming the string is in a recognizable date format
      },
      {
        id: "totalCost",
        label: "Total Cost",
        accessor: (item: MonthlyCommunicationCost) =>
          formatCurrency(
            item.dailyCosts.reduce((sum, curr) => sum + curr.totalCost, 0),
            true,
          ),
        format: (value: number) => formatCurrency(value, true),
      },
    ],
    [],
  );

  const isAdmin = useMemo(() => {
    const adminRoles = ["Super Admin"]; // Adjust based on your role names
    const currentUserRole =
      roles.find((role) => role.roleID === currentUser?.roleID)?.roleName || "";
    return adminRoles.includes(currentUserRole);
  }, [currentUser, roles]);
  if (isLoading || !roles || !currentUser || !selectedPartner) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <>
      <div id="main-content">
        <Box padding={theme.spacing(3)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                justifyContent="space-between"
                display="flex"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Manage Monthly Costs
                </Typography>
              </Box>
              <Box>
                {isAdmin && (
                  <Button
                    onClick={() => handleOpen("create")}
                    variant="contained"
                    color="primary"
                  >
                    Add New
                  </Button>
                )}
              </Box>
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12}>
                  {filteredSmsUssdMonthlyCost &&
                  filteredSmsUssdMonthlyCost.length > 0 ? (
                    <ReusableTable
                      data={filteredSmsUssdMonthlyCost}
                      columns={columns}
                      paginationOptions={{
                        rowsPerPage: 20,
                        rowsPerPageOptions: [10, 20, 30],
                        showPagination: true,
                      }}
                      enableRowClick={true}
                      onRowClick={handleRowClick}

                      //   onRowClick={(item) => {
                      //     if (roleName === "Super Admin" || roleName === "Admin") {
                      //       handleUserClick(item);
                      //     }
                      //   }}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 200,
                        mt: 4,
                      }}
                    >
                      <InfoIcon color="action" sx={{ fontSize: 60 }} />
                      <Typography variant="subtitle1" color="textSecondary">
                        No available ussd - sms cost.
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Modal open={openModal} onClose={handleClose} closeAfterTransition>
        <Fade in={openModal}>
          <Box
            id="modal"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: "70%", md: "900px" }, // Responsive width
              maxHeight: "90vh", // Maximum height
              overflowY: "auto", // Enable scrolling
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {actionState == "create"
                ? "Add new month sms-ussd cost"
                : "Ussd and Sms monthly cost"}
            </Typography>
            <Formik
              initialValues={{
                businessID:
                  actionState === "create"
                    ? ""
                    : (selectedCost?.businessID ?? ""),
                monthStart:
                  actionState === "create"
                    ? ""
                    : dayjs(selectedCost?.monthStart).format(
                        "YYYY-MM-DDTHH:mm",
                      ),
                dailyCosts:
                  actionState === "create"
                    ? []
                    : (selectedCost?.dailyCosts || []).map((cost) => ({
                        ...cost,
                        id: cost.id || uuidv4(),
                        date: dayjs(cost.date).format("YYYY-MM-DDTHH:mm"), // format for input
                        totalCost:
                          cost.totalCost ??
                          (cost.smsCost || 0) + (cost.ussdCost || 0), // fallback
                      })),
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} md={6} mb={2}>
                      <FormikTextField
                        name="businessID"
                        label="Business ID"
                        readOnly={actionState === "edit" && !isAdmin}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} mb={2}>
                      <FormikTextField
                        name="monthStart"
                        type="datetime-local"
                        label="Month Start"
                        readOnly={actionState === "edit" && !isAdmin}
                      />
                    </Grid>
                  </Grid>
                  {actionState === "edit" ? (
                    <Box mt={3}>
                      <ReusableTable<DailyCost>
                        data={values.dailyCosts}
                        columns={[
                          {
                            id: "date",
                            label: "Date",
                            render: (row: DailyCost) => {
                              // Find the absolute index using the unique id
                              const absoluteIndex = values.dailyCosts.findIndex(
                                (item: DailyCost) => item.id === row.id,
                              );
                              return (
                                <TextField
                                  type="datetime-local"
                                  size="small"
                                  // Bind directly from Formik state
                                  value={dayjs(row.date).format(
                                    "YYYY-MM-DDTHH:mm",
                                  )}
                                  InputProps={{ readOnly: !isAdmin }}
                                  onChange={(e) => {
                                    const newDate = e.target.value;
                                    setFieldValue(
                                      `dailyCosts.${absoluteIndex}.date`,
                                      newDate,
                                    );
                                  }}
                                />
                              );
                            },
                          },
                          {
                            id: "smsCost",
                            label: "SMS Cost",
                            render: (row: DailyCost) => {
                              const absoluteIndex = values.dailyCosts.findIndex(
                                (item: DailyCost) => item.id === row.id,
                              );
                              return (
                                <TextField
                                  type="number"
                                  size="small"
                                  value={row.smsCost}
                                  InputProps={{ readOnly: !isAdmin }}
                                  onChange={(e) => {
                                    const sms = parseFloat(e.target.value) || 0;
                                    const ussd = row.ussdCost || 0;
                                    setFieldValue(
                                      `dailyCosts.${absoluteIndex}.smsCost`,
                                      sms,
                                    );
                                    setFieldValue(
                                      `dailyCosts.${absoluteIndex}.totalCost`,
                                      sms + ussd,
                                    );
                                  }}
                                />
                              );
                            },
                          },
                          {
                            id: "ussdCost",
                            label: "USSD Cost",
                            render: (row: DailyCost) => {
                              const absoluteIndex = values.dailyCosts.findIndex(
                                (item: DailyCost) => item.id === row.id,
                              );
                              return (
                                <TextField
                                  type="number"
                                  size="small"
                                  value={row.ussdCost}
                                  InputProps={{ readOnly: !isAdmin }}
                                  onChange={(e) => {
                                    const ussd =
                                      parseFloat(e.target.value) || 0;
                                    const sms = row.smsCost || 0;
                                    setFieldValue(
                                      `dailyCosts.${absoluteIndex}.ussdCost`,
                                      ussd,
                                    );
                                    setFieldValue(
                                      `dailyCosts.${absoluteIndex}.totalCost`,
                                      sms + ussd,
                                    );
                                  }}
                                />
                              );
                            },
                          },
                          {
                            id: "totalCost",
                            label: "Total",
                            accessor: (row: DailyCost) =>
                              formatCurrency(row.totalCost, true),
                          },
                        ]}
                        showEditIcon={false}
                        showDeleteIcon={isAdmin}
                        onDelete={(row) => {
                          const updated = values.dailyCosts.filter(
                            (item) => item.id !== row.id,
                          );
                          setFieldValue("dailyCosts", updated);
                        }}
                        paginationOptions={{
                          showPagination: true,
                          rowsPerPage: 10,
                        }}
                      />
                      {isAdmin && (
                        <Button
                          sx={{ mt: 2 }}
                          onClick={() => {
                            // Automatically add the next day of the month if needed
                            const lastDate = values.dailyCosts.length
                              ? dayjs(
                                  values.dailyCosts[
                                    values.dailyCosts.length - 1
                                  ].date,
                                )
                              : dayjs(values.monthStart);

                            const nextDate = lastDate
                              .add(1, "day")
                              .format("YYYY-MM-DDTHH:mm");

                            setFieldValue("dailyCosts", [
                              ...values.dailyCosts,
                              {
                                id: uuidv4(),
                                date: nextDate,
                                smsCost: 0,
                                ussdCost: 0,
                                totalCost: 0,
                              },
                            ]);
                          }}
                        >
                          Add Daily Cost
                        </Button>
                      )}
                    </Box>
                  ) : (
                    <FieldArray name="dailyCosts">
                      {({ push, remove }) => (
                        <>
                          {values.dailyCosts.map(
                            (cost: DailyCost, index: number) => (
                              <Grid
                                container
                                spacing={2}
                                key={cost.id ?? index}
                              >
                                <Grid item xs={12} md={3} mb={2}>
                                  <FormikTextField
                                    name={`dailyCosts.${index}.date`}
                                    type="datetime-local"
                                    label="Date"
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} mb={2}>
                                  <FormikTextField
                                    name={`dailyCosts.${index}.smsCost`}
                                    type="number"
                                    label="SMS Cost"
                                    onChange={(e: {
                                      target: { value: any };
                                    }) => {
                                      const sms =
                                        parseFloat(e.target.value) ?? 0;
                                      const ussd = cost.ussdCost ?? 0;
                                      setFieldValue(
                                        `dailyCosts.${index}.smsCost`,
                                        sms,
                                      );
                                      setFieldValue(
                                        `dailyCosts.${index}.totalCost`,
                                        sms + ussd,
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3} mb={2}>
                                  <FormikTextField
                                    name={`dailyCosts.${index}.ussdCost`}
                                    type="number"
                                    label="USSD Cost"
                                    onChange={(e: {
                                      target: { value: string };
                                    }) => {
                                      const ussd =
                                        parseFloat(e.target.value) ?? 0;
                                      const sms = cost.smsCost ?? 0;
                                      setFieldValue(
                                        `dailyCosts.${index}.ussdCost`,
                                        ussd,
                                      );
                                      setFieldValue(
                                        `dailyCosts.${index}.totalCost`,
                                        sms + ussd,
                                      );
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} md={3} mb={2}>
                                  <FormikTextField
                                    name={`dailyCosts.${index}.totalCost`}
                                    label="Total Cost"
                                    type="number"
                                    readOnly
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  {isAdmin && (
                                    <Button
                                      type="button"
                                      onClick={() => remove(index)}
                                      fullWidth
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            ),
                          )}

                          {isAdmin && (
                            <Button
                              type="button"
                              onClick={() => {
                                const nextDate = values.dailyCosts.length
                                  ? dayjs(
                                      values.dailyCosts[
                                        values.dailyCosts.length - 1
                                      ].date,
                                    )
                                      .add(1, "day")
                                      .format("YYYY-MM-DDTHH:mm")
                                  : dayjs(values.monthStart)
                                      .add(1, "day")
                                      .format("YYYY-MM-DDTHH:mm");
                                push({
                                  id: uuidv4(), // Assigning a new UUID to each new entry
                                  date: nextDate,
                                  smsCost: 0,
                                  ussdCost: 0,
                                  totalCost: 0,
                                });
                              }}
                              sx={{ mt: 2 }}
                            >
                              Add Daily Cost
                            </Button>
                          )}
                        </>
                      )}
                    </FieldArray>
                  )}

                  {isAdmin && (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3 }}
                    >
                      {actionState === "edit"
                        ? "Update Monthly Cost"
                        : "Create Monthly Cost"}
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UssdSms;
