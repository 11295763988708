// src/slices/usersSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ManualDailyWinnerResponse,
  ManualMonthlyWinnerResponse,
  RaffleSettingsModel,
  RaffleState,
  RaffleTicketModel,
  RaffleTransactionModel,
  RaffleWinnerCashOutPaymentModel,
  RaffleWinnerModel,
} from "../../@types/raffle";
import {
  fetchDynamicTickets,
  fetchPast3DaysRaffleWinners,
  getAllRaffleTickets,
  getAllRaffleTransactions,
  getAllRaffleWinnerPayouts,
  getAllRaffleWinners,
  getRaffleSettingsData,
  initiateDailyDraws,
  initiateMonthlyDraws,
  maunalTicketPurchase,
  payWinnerMoney,
  selectRaffleWinner,
  sendUsersMessages,
  updateRaffleSettingsData,
} from "../thunks/raffle-thunk";

const initialRaffleState: RaffleState = {
  raffleTickets: [],
  raffleTrasactions: [],
  raffleWinners: [],
  rafflePast3DaysWinners: [],
  rafflePayouts: [],
  raffleSetting: null,
  selectedWinners: [],
  raffleTicketDynamic: [],
  status: "idle",
  loading: false,
  message: null,
  error: null,
  manualMonthlyTriggerInfo: null,
  manualDailyTriggerInfo: null,
};

const raffleSlice = createSlice({
  name: "raffles",
  initialState: initialRaffleState,
  reducers: {
    resetPreviousWinners: (state) => {
      state.manualMonthlyTriggerInfo = null;
      state.manualDailyTriggerInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRaffleTickets.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        getAllRaffleTickets.fulfilled,
        (state, action: PayloadAction<RaffleTicketModel[]>) => {
          state.raffleTickets = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(getAllRaffleTickets.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(fetchPast3DaysRaffleWinners.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        fetchPast3DaysRaffleWinners.fulfilled,
        (state, action: PayloadAction<RaffleWinnerModel[]>) => {
          state.rafflePast3DaysWinners = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(fetchPast3DaysRaffleWinners.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(getAllRaffleWinners.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        getAllRaffleWinners.fulfilled,
        (state, action: PayloadAction<RaffleWinnerModel[]>) => {
          state.raffleWinners = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(getAllRaffleWinners.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(selectRaffleWinner.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        selectRaffleWinner.fulfilled,
        (state, action: PayloadAction<RaffleWinnerModel[]>) => {
          state.selectedWinners = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(selectRaffleWinner.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(fetchDynamicTickets.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        fetchDynamicTickets.fulfilled,
        (state, action: PayloadAction<RaffleTicketModel[]>) => {
          state.raffleTicketDynamic = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(fetchDynamicTickets.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(getAllRaffleTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        getAllRaffleTransactions.fulfilled,
        (state, action: PayloadAction<RaffleTransactionModel[]>) => {
          state.raffleTrasactions = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(getAllRaffleTransactions.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })

      .addCase(getRaffleSettingsData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        getRaffleSettingsData.fulfilled,
        (state, action: PayloadAction<RaffleSettingsModel>) => {
          state.raffleSetting = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(getRaffleSettingsData.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(updateRaffleSettingsData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(updateRaffleSettingsData.fulfilled, (state) => {
        state.loading = false;
        state.message = null;
      })
      .addCase(updateRaffleSettingsData.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = null;
        } else {
          state.error = action.error.message || null;
        }
      })
      .addCase(payWinnerMoney.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(payWinnerMoney.fulfilled, (state) => {
        state.loading = false;
        state.message = null;
      })
      .addCase(payWinnerMoney.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = null;
        } else {
          state.error = action.error.message || null;
        }
      })
      .addCase(getAllRaffleWinnerPayouts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        getAllRaffleWinnerPayouts.fulfilled,
        (state, action: PayloadAction<RaffleWinnerCashOutPaymentModel[]>) => {
          state.rafflePayouts = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(getAllRaffleWinnerPayouts.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(initiateMonthlyDraws.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        initiateMonthlyDraws.fulfilled,
        (state, action: PayloadAction<ManualMonthlyWinnerResponse>) => {
          state.manualMonthlyTriggerInfo = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(initiateMonthlyDraws.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(initiateDailyDraws.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        initiateDailyDraws.fulfilled,
        (state, action: PayloadAction<ManualDailyWinnerResponse>) => {
          state.manualDailyTriggerInfo = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(initiateDailyDraws.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(maunalTicketPurchase.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(maunalTicketPurchase.fulfilled, (state) => {
        state.loading = false;
        state.message = null;
        state.status = "succeeded";
      })
      .addCase(maunalTicketPurchase.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      })
      .addCase(sendUsersMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(sendUsersMessages.fulfilled, (state) => {
        state.loading = false;
        state.message = null;
        state.status = "succeeded";
      })
      .addCase(sendUsersMessages.rejected, (state, action) => {
        if (action.payload) {
          state.status = "failed";
          state.error = null;
          state.loading = false;
          state.message = null;
        } else {
          state.status = "failed";
          state.error = action.error.message || null;
          state.loading = false;
          state.message = null;
        }
      });
  },
});

export const { resetPreviousWinners } = raffleSlice.actions;

export default raffleSlice.reducer;
