import { RootState } from "../store";

export const productsLoadingState = (state: RootState) =>
  state.products.loading;
export const productStateError = (state: RootState) => state.products.error;
export const ProductStateMessage = (state: RootState) => state.products.message;
export const productsSelectorList = (state: RootState) => state.products.data;
export const productsHistorySelectorList = (state: RootState) =>
  state.products.productsHistory;

export const boxLoadingState = (state: RootState) => state.box.loading;
export const boxStateError = (state: RootState) => state.box.error;
export const boxStateMessage = (state: RootState) => state.box.message;
export const boxesSelectorList = (state: RootState) => state.box.data;

export const vtuPurchases = (state: RootState) => state.products.vtuPurchases;
export const vtuTotalPurchases = (state: RootState) =>
  state.products.vtuAggregateAmount;
