import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  raffleSettingsData,
  raffleTicketsList,
  raffleWinners,
} from "../../../../selectors/raffleSelector";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import {
  staffSelectorData,
  staffSelectorLoadingState,
} from "../../../../selectors/staffSelector";
import { Spin } from "antd";
import FixInfoCard from "../info-fix-card";
import { formatCurrency } from "../../../../utils";
import { RaffleTicketModel } from "../../../../@types/raffle";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { SelectChangeEvent } from "@mui/material/Select";
import { Column as AntsColumnPlot, Line } from "@ant-design/plots";
import { StaffModel } from "../../../../@types/staff-model";
import FeeInfoAlert from "../alert-info";
import InfoIcon from "@mui/icons-material/Info";
import BarChartIcon from "@mui/icons-material/BarChart";
import { StackedLineChartRounded } from "@mui/icons-material";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import { allUssdSmsCostData } from "../../../../selectors/ussdSmsSelectors";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

// Set the Nigerian timezone identifier
const nigeriaTZ = "Africa/Lagos";

const today = dayjs().tz(nigeriaTZ).startOf("day");
const endOfDay = dayjs().tz(nigeriaTZ).endOf("day");

interface TicketCountPerChannel {
  [channelID: string]: number; // Allows dynamic access using string keys
}

interface ChartDataItem {
  category: string;
  value: number;
}

interface RevenuePerChannel {
  [channelID: string]: number; // Similarly for revenue
}

interface DateRange {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}

const dateRanges: Record<string, DateRange> = {
  today: { start: today, end: endOfDay },
  yesterday: {
    start: today.subtract(1, "day"),
    end: endOfDay.subtract(1, "day"),
  },
  thisWeek: { start: today.startOf("week"), end: today.endOf("week") },
  lastWeek: {
    start: today.subtract(1, "week").startOf("week"),
    end: today.subtract(1, "week").endOf("week"),
  },
  thisMonth: { start: today.startOf("month"), end: today.endOf("month") },
  lastMonth: {
    start: today.subtract(1, "month").startOf("month"),
    end: today.subtract(1, "month").endOf("month"),
  },
  // custom: Implement custom date range logic
};

interface HourlySalesData {
  hour: string;
  value: number;
  day: string;
}

const PartnerDashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLoading = useSelector(staffSelectorLoadingState);

  const partners = useSelector(staffSelectorData);
  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);
  const raffleTicketsData = useSelector(raffleTicketsList) || [];
  const raffleWinnersData = useSelector(raffleWinners) || [];
  const raffleSettings = useSelector(raffleSettingsData);
  const allSmsUssdCost = useSelector(allUssdSmsCostData);
  const currentMonthRange = dateRanges.thisMonth;

  const [selectedPartner, setSelectedPartner] = useState<string>("All");
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("today");
  const [openModal, setOpenModal] = useState(false);

  const [customDateRange, setCustomDateRange] = useState<{
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  }>({
    start: today,
    end: endOfDay,
  });

  const [tempDateRange, setTempDateRange] = useState({
    start: today.format("YYYY-MM-DD"),
    end: endOfDay.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    // Automatically select the partner if the user is a partner
    if (user && roles) {
      const userRole = roles.find((role) => role.roleID === user.roleID);
      if (userRole?.roleName.includes("Admin")) {
        setSelectedPartner("All"); // Admins can view all or select a partner
      } else if (userRole?.roleName === "ReadOnlyPartner") {
        setSelectedPartner(user.staffID); // Automatically select partner
      }
    }
  }, [user, roles]);

  const timePeriods = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "thisWeek", label: "This Week" },
    { value: "lastWeek", label: "Last Week" },
    { value: "thisMonth", label: "This Month" },
    { value: "lastMonth", label: "Last Month" },
    { value: "custom", label: "Custom" },
  ];
  const handleDateRangeChange = (event: SelectChangeEvent<string>) => {
    const period = event.target.value;
    if (period === "custom") {
      setOpenModal(true);
    } else {
      setSelectedTimePeriod(period);
      setCustomDateRange(dateRanges[period] || dateRanges.today);
    }
  };

  const handleApplyCustomDate = () => {
    setCustomDateRange({
      start: dayjs(tempDateRange.start),
      end: dayjs(tempDateRange.end),
    });
    setSelectedTimePeriod("custom");
    setOpenModal(false);
  };

  const handleCancelCustomDate = () => {
    setCustomDateRange(dateRanges.today); // Reset to today
    setSelectedTimePeriod("today");
    setOpenModal(false);
  };

  const handlePartnerChange = (event: SelectChangeEvent<string>) => {
    setSelectedPartner(event.target.value);
  };

  const filteredPartners = useMemo(() => {
    return partners.filter((partner: StaffModel) => {
      const role = roles.find((r) => r.roleID === partner.roleID);
      return role && role.roleName === "ReadOnlyPartner";
    });
  }, [partners, roles]);

  const filteredTickets = useMemo(() => {
    if (!selectedPartner || selectedPartner === "All") {
      // Return all tickets within the date range if no specific partner is selected
      return raffleTicketsData.filter(
        (ticket) =>
          dayjs(ticket.raffleDate).isSameOrAfter(customDateRange.start) &&
          dayjs(ticket.raffleDate).isSameOrBefore(customDateRange.end),
      );
    }

    const partner = filteredPartners.find((p) => p.staffID === selectedPartner);
    const partnerBusinessStartDate = partner
      ? dayjs(partner.businessStartDate)
      : null;
    const BSID = partner && partner.businessID;

    // Filter tickets based on the partner's creation date
    return raffleTicketsData.filter(
      (ticket) =>
        dayjs(ticket.raffleDate).isAfter(partnerBusinessStartDate) &&
        ticket.businessID === BSID &&
        dayjs(ticket.raffleDate).isSameOrAfter(customDateRange.start) &&
        dayjs(ticket.raffleDate).isSameOrBefore(customDateRange.end),
    );
  }, [raffleTicketsData, selectedPartner, filteredPartners, customDateRange]);

  const filteredWinners = useMemo(() => {
    if (!selectedPartner || selectedPartner === "All") {
      // Return all winners within the date range if no specific partner is selected
      return raffleWinnersData.filter(
        (winner) =>
          dayjs(winner.drawDate).isSameOrAfter(customDateRange.start) &&
          dayjs(winner.drawDate).isSameOrBefore(customDateRange.end),
      );
    }

    const partner = filteredPartners.find((p) => p.staffID === selectedPartner);
    const partnerBusinessStartDate = partner
      ? dayjs(partner.businessStartDate)
      : null;
    const BSID = partner && partner.businessID;

    // Filter winners based on the partner's creation date
    return raffleWinnersData.filter(
      (winner) =>
        dayjs(winner.drawDate).isAfter(partnerBusinessStartDate) &&
        winner.businessID === BSID &&
        dayjs(winner.drawDate).isAfter(customDateRange.start) &&
        dayjs(winner.drawDate).isSameOrBefore(customDateRange.end),
    );
  }, [raffleWinnersData, selectedPartner, filteredPartners, customDateRange]);

  const allTimeUniqueUsers = useMemo(() => {
    const users = new Set();

    const filteredTickets =
      selectedPartner && selectedPartner !== "All"
        ? raffleTicketsData.filter((ticket) => {
            const partner = filteredPartners.find(
              (p) => p.staffID === selectedPartner,
            );
            const partnerBusinessStartDate = partner
              ? dayjs(partner.businessStartDate)
              : null;
            return (
              partner &&
              ticket.businessID === partner.businessID &&
              dayjs(ticket.raffleDate).isAfter(partnerBusinessStartDate)
            );
          })
        : raffleTicketsData;

    filteredTickets.forEach((ticket) => {
      if (ticket.userPhoneNumber) {
        users.add(ticket.userPhoneNumber);
      }
    });
    return users;
  }, [raffleTicketsData, selectedPartner, filteredPartners]);

  const ticketCounts = useMemo(() => {
    // Filter tickets based on selected partner and conditions
    const filteredTickets =
      selectedPartner && selectedPartner !== "All"
        ? raffleTicketsData.filter((ticket) => {
            const partner = filteredPartners.find(
              (p) => p.staffID === selectedPartner,
            );
            const partnerBusinessStartDate = partner
              ? dayjs(partner.businessStartDate)
              : null;
            return (
              partner &&
              ticket.businessID === partner.businessID &&
              dayjs(ticket.raffleDate).isAfter(partnerBusinessStartDate)
            );
          })
        : raffleTicketsData;

    // Reduce filtered tickets to count occurrences by userPhoneNumber
    return filteredTickets.reduce(
      (acc, ticket) => {
        const userKey = ticket.userPhoneNumber;
        acc[userKey] = (acc[userKey] || 0) + 1;
        return acc;
      },
      {} as { [userPhoneNumber: string]: number },
    );
  }, [raffleTicketsData, selectedPartner, filteredPartners, customDateRange]);

  const channelPrices = useMemo(() => {
    const prices: { [key: string]: number } = {};
    if (raffleSettings && raffleSettings.raffleChannels) {
      raffleSettings.raffleChannels.forEach((channel) => {
        prices[channel.channelID] = channel.entryPrice;
      });
    }
    return prices;
  }, [raffleSettings]);

  const totalRevenueForPeriod = useMemo(() => {
    return filteredTickets.reduce((acc, ticket) => {
      const ticketPrice = channelPrices[ticket.channelID] || 0; // Assuming each ticket has a 'channelID' and that 'channelPrices' maps these IDs to their prices.
      return acc + ticketPrice;
    }, 0);
  }, [filteredTickets, channelPrices]);

  const totalRevenueForCurrentMonth = useMemo(() => {
    return raffleTicketsData
      .filter((ticket) =>
        dayjs(ticket.raffleDate).isBetween(
          currentMonthRange.start,
          currentMonthRange.end,
          null,
          "[]",
        ),
      )
      .reduce((acc, ticket) => {
        const price = channelPrices[ticket.channelID] || 0;
        return acc + price;
      }, 0);
  }, [raffleTicketsData, channelPrices]);

  const totalPaidOutThisMonth = useMemo(() => {
    return raffleWinnersData
      .filter((winner) =>
        dayjs(winner.drawDate).isBetween(
          currentMonthRange.start,
          currentMonthRange.end,
          null,
          "[]",
        ),
      )
      .reduce((acc, winner) => {
        return winner.paymentPaid ? acc + winner.price : acc;
      }, 0);
  }, [raffleWinnersData]);

  const ussdSmsCostForCurrentMonth = useMemo(() => {
    if (
      !allSmsUssdCost ||
      !Array.isArray(allSmsUssdCost) ||
      selectedPartner === "All"
    )
      return 0;

    const selectedBusinessID = partners.find(
      (p) => p.staffID === selectedPartner,
    )?.businessID;

    if (!selectedBusinessID) return 0;

    const filteredCosts = allSmsUssdCost.filter(
      (entry) => entry.businessID === selectedBusinessID,
    );
    // Flatten all dailyCosts across the filtered records
    const allDailyCostsThisMonth = filteredCosts.flatMap((entry) =>
      entry.dailyCosts.filter((cost) =>
        dayjs(cost.date).isBetween(
          currentMonthRange.start,
          currentMonthRange.end,
          null,
          "[]",
        ),
      ),
    );
    // Sum all totalCost values
    const total = allDailyCostsThisMonth.reduce(
      (sum, curr) => sum + (curr.totalCost || 0),
      0,
    );

    return total;
  }, [allSmsUssdCost, selectedPartner, partners]);

  const netProfitForCurrentMonth = useMemo(() => {
    const grossProfit = totalRevenueForCurrentMonth - totalPaidOutThisMonth;
    return grossProfit - ussdSmsCostForCurrentMonth;
  }, [
    totalRevenueForCurrentMonth,
    totalPaidOutThisMonth,
    ussdSmsCostForCurrentMonth,
  ]);

  if (
    isLoading ||
    !user ||
    !filteredTickets ||
    !filteredWinners ||
    !raffleSettings ||
    !partners
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };

  const categorizeUserPurchases = (ticketCounts: {
    [userPhoneNumber: string]: number;
  }) => {
    const categories = {
      "1": 0,
      "2-3": 0,
      "4-5": 0,
      "6-10": 0,
      "11-20": 0,
      "21-50": 0,
      "51+": 0,
    };

    Object.values(ticketCounts).forEach((count) => {
      if (count === 1) categories["1"] += 1;
      else if (count >= 2 && count <= 3) categories["2-3"] += 1;
      else if (count >= 4 && count <= 5) categories["4-5"] += 1;
      else if (count >= 6 && count <= 10) categories["6-10"] += 1;
      else if (count >= 11 && count <= 20) categories["11-20"] += 1;
      else if (count >= 21 && count <= 50) categories["21-50"] += 1;
      else categories["51+"] += 1;
    });

    return categories;
  };

  const purchaseCategories = categorizeUserPurchases(ticketCounts);

  const chartFreqData: ChartDataItem[] = Object.entries(purchaseCategories).map(
    ([category, value]) => ({
      category,
      value,
    }),
  );

  const calculateRevenuePerChannel = (
    tickets: RaffleTicketModel[],
    prices: { [key: string]: number },
  ): RevenuePerChannel => {
    return tickets.reduce<RevenuePerChannel>((acc, ticket) => {
      const price = prices[ticket.channelID] || 0;
      acc[ticket.channelID] = (acc[ticket.channelID] || 0) + price;
      return acc;
    }, {} as RevenuePerChannel);
  };

  // Assuming channelPrices is defined
  const revenuePerChannel = calculateRevenuePerChannel(
    filteredTickets,
    channelPrices,
  );

  // Assuming raffleTicketsData is an array of tickets where each ticket has a channelID.
  const ticketCountPerChannel = filteredTickets.reduce<TicketCountPerChannel>(
    (acc, ticket) => {
      acc[ticket.channelID] = (acc[ticket.channelID] || 0) + 1;
      return acc;
    },
    {},
  );

  // Calculate total amount expected to be paid out to winners (unpaid prizes)
  const totalAmountExpectedToPayOut = filteredWinners.reduce((acc, winner) => {
    if (!winner.paymentPaid) {
      return acc + winner.price;
    }
    return acc;
  }, 0);

  // Calculate total amount actually paid out (paid prizes)
  const totalAmountPaidOut = filteredWinners.reduce((acc, winner) => {
    if (winner.paymentPaid) {
      return acc + winner.price;
    }
    return acc;
  }, 0);

  // Calculate Profit
  const profit = totalRevenueForPeriod - totalAmountPaidOut;
  const profitChangeType = profit >= 0 ? "increase" : "decrease";

  const getLabelForSelectedPeriod = (selectedPeriod: string) => {
    const period = timePeriods.find(
      (period) => period.value === selectedPeriod,
    );
    return period ? period.label : "Unknown period"; // Return 'Unknown period' if no match is found
  };

  const groupTicketsByDayOfWeekAndHour = (
    tickets: RaffleTicketModel[],
  ): HourlySalesData[] => {
    // Labels for days of the week (dayjs().day() returns 0 for Sunday, 1 for Monday, etc.)
    const dayOfWeekLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Create a complete grid: 7 days x 24 hours, each with initial count 0.
    const results: HourlySalesData[] = [];
    for (let d = 0; d < 7; d++) {
      for (let hour = 0; hour < 24; hour++) {
        results.push({
          day: dayOfWeekLabels[d],
          hour: `${hour}:00`,
          value: 0,
        });
      }
    }

    // Aggregate counts: for each ticket, determine the day-of-week and hour
    tickets.forEach((ticket) => {
      const ticketDate = dayjs(ticket.raffleDate);
      const dayIndex = ticketDate.day(); // 0 for Sunday, 1 for Monday, etc.
      const hour = ticketDate.hour();
      const dayLabel = dayOfWeekLabels[dayIndex];
      const hourLabel = `${hour}:00`;

      // Find the corresponding entry and increment its count
      const entry = results.find(
        (e) => e.day === dayLabel && e.hour === hourLabel,
      );
      if (entry) {
        entry.value += 1;
      }
    });

    return results;
  };

  const label = getLabelForSelectedPeriod(selectedTimePeriod);
  const remainingPayoutChange =
    totalAmountExpectedToPayOut > 0 ? "decrease" : "increase";
  const totalPaidChange =
    totalAmountExpectedToPayOut === 0 ? "increase" : "decrease";

  const roleName = getRoleData(user?.roleID)?.roleName || "ReadOnlyPartner";

  const allValuesAreZero = chartFreqData.every((item) => item.value === 0);

  const hourlyData = groupTicketsByDayOfWeekAndHour(filteredTickets);

  return (
    <Box sx={{ p: isMobile ? 1 : 3 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        mb={3}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Raffles Dashboard
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={
          roleName === "Super Admin" || roleName === "Admin"
            ? "space-between"
            : "flex-end"
        }
        sx={{ p: 2 }}
      >
        {(roleName === "Super Admin" || roleName === "Admin") && (
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="partner-select-label">Select Partner</InputLabel>
              <Select
                labelId="partner-select-label"
                id="partner-select"
                value={selectedPartner}
                onChange={handlePartnerChange}
              >
                <MenuItem value="All">
                  <em>All</em>
                </MenuItem>
                {filteredPartners.map((partner) => (
                  <MenuItem key={partner.staffID} value={partner.staffID}>
                    {`${partner.firstName} ${partner.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="time-period-label">Time Period</InputLabel>
            <Select
              labelId="time-period-label"
              id="time-period-select"
              value={selectedTimePeriod}
              onChange={handleDateRangeChange}
            >
              {timePeriods.map((period) => (
                <MenuItem key={period.value} value={period.value}>
                  {period.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {selectedTimePeriod === "custom" && (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Typography variant="body2">
            Custom Range: {customDateRange.start.format("YYYY-MM-DD")} -{" "}
            {customDateRange.end.format("YYYY-MM-DD")}
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleCancelCustomDate}
          >
            Clear
          </Button>
        </Box>
      )}
      <Grid container spacing={2} mb={5}>
        {roleName !== "RafflePlay" && (
          <Grid
            item
            xs={12}
            md={6}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FeeInfoAlert />
          </Grid>
        )}
        <Grid container spacing={2} mt={4} alignItems="stretch">
          {roleName !== "RafflePlay" && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              container
              direction="column"
              spacing={2}
            >
              <Grid item>
                <FixInfoCard
                  info={`Total Revenue ${label}`}
                  value={formatCurrency(totalRevenueForPeriod, true)}
                />
              </Grid>
              <Grid item>
                <FixInfoCard
                  info={`Gross Revenue ${label}`}
                  subInfo="Without USSD, SMS, and Paystack fees"
                  profitChange={profitChangeType}
                  value={formatCurrency(profit, true)}
                />
              </Grid>
              <Grid item>
                <FixInfoCard
                  info={`Raffle Tickets  ${label}`}
                  value={filteredTickets.length.toString()}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6">User Participation Frequency</Typography>
            {!allValuesAreZero ? (
              <AntsColumnPlot
                data={chartFreqData}
                xField="category"
                yField="value"
                colorField="category"
                isGroup
                columnStyle={({ category }: ChartDataItem) => ({
                  fill: category.includes("+") ? "#fa541c" : "#1890ff",
                })}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={470} // Specify a height for the placeholder area
                sx={{
                  backgroundColor: theme.palette.grey[100], // Use theme for colors for consistency
                  borderRadius: theme.shape.borderRadius,
                  border: `1px dashed ${theme.palette.grey[400]}`,
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Box color="action" sx={{ fontSize: 60 }}>
                    <BarChartIcon sx={{ fontSize: 100 }} />
                  </Box>
                  No data available for the selected period
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

        {roleName !== "RafflePlay" && (
          <>
            <Grid item xs={12} md={6} mb={2}>
              <FixInfoCard
                info={`Estimated Gross Profit for Current Month (finalized at month end)`}
                value={formatCurrency(netProfitForCurrentMonth, true)}
                profitChange={"increase"}
              />
            </Grid>

            <Grid item xs={12} md={6} mb={2}>
              <FixInfoCard
                info={`Estimated USSD/SMS Cost for Current Month (subject to change)`}
                value={formatCurrency(ussdSmsCostForCurrentMonth, true)}
                profitChange={"decrease"}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={4} mb={2}>
          <FixInfoCard
            info={`Remaining Payout ${label}`}
            value={formatCurrency(totalAmountExpectedToPayOut, true)}
            profitChange={remainingPayoutChange}
          />
        </Grid>
        <Grid item xs={12} md={4} mb={2}>
          <FixInfoCard
            info={`Total Paid ${label}`}
            value={formatCurrency(totalAmountPaidOut, true)}
            profitChange={totalPaidChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info={`Raffle Winners ${label}`}
            value={filteredWinners.length.toString()}
          />{" "}
        </Grid>
        {roleName !== "RafflePlay" && (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <FixInfoCard
                info={`Total Unique Users`}
                value={allTimeUniqueUsers.size.toString()}
              />
            </Grid>

            {Object.keys(revenuePerChannel).length > 0 ? (
              Object.keys(revenuePerChannel).map((channelID) => (
                <Grid key={channelID} item>
                  <FixInfoCard
                    info={`Channel ${channelID} Revenue`}
                    value={formatCurrency(revenuePerChannel[channelID], true)}
                  />
                </Grid>
              ))
            ) : (
              <Grid item>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={150}
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(2),
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{ verticalAlign: "middle", fontSize: "1.5rem" }}
                    >
                      <InfoIcon />
                    </span>{" "}
                    No revenue data available for this period.
                  </Typography>
                </Box>
              </Grid>
            )}
            {Object.keys(ticketCountPerChannel).length > 0 ? (
              Object.keys(ticketCountPerChannel).map((channelID) => (
                <Grid key={channelID} item>
                  <FixInfoCard
                    info={`Channel ${channelID} Tickets ${label}`}
                    value={ticketCountPerChannel[channelID].toString()}
                  />
                </Grid>
              ))
            ) : (
              <Grid item>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={150}
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(2),
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{ verticalAlign: "middle", fontSize: "1.5rem" }}
                    >
                      <InfoIcon />
                    </span>{" "}
                    No ticket data available for this period.
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={8}>
          <Typography variant="h6">{`Hourly Ticket Sales ${label}`}</Typography>{" "}
          {!allValuesAreZero ? (
            <Line
              data={hourlyData}
              xField="hour"
              yField="value"
              seriesField="day"
              smooth
              color={[
                "#7189bf",
                "#df7599",
                "#ffc785",
                "#72d6c9",
                "#f3a683",
                "#70a1ff",
                "#7bed9f",
              ]} // Example colors for each day
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={470} // Specify a height for the placeholder area
              sx={{
                backgroundColor: theme.palette.grey[100], // Use theme for colors for consistency
                borderRadius: theme.shape.borderRadius,
                border: `1px dashed ${theme.palette.grey[400]}`,
              }}
            >
              <Typography
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: theme.palette.text.secondary,
                }}
              >
                <Box color="action" sx={{ fontSize: 60 }}>
                  <StackedLineChartRounded sx={{ fontSize: 100 }} />
                </Box>
                No data available for the selected period
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>

      <Dialog open={openModal} onClose={handleCancelCustomDate}>
        <DialogTitle>Select Custom Date Range</DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date"
            value={tempDateRange.start}
            onChange={(e) =>
              setTempDateRange((prev) => ({ ...prev, start: e.target.value }))
            }
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            value={tempDateRange.end}
            onChange={(e) =>
              setTempDateRange((prev) => ({ ...prev, end: e.target.value }))
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCustomDate} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleApplyCustomDate} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PartnerDashboard;
