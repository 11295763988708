import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  usersSelectorList,
  usersSelectorLoadingState,
} from "../../../../selectors/userSelector";
// import { UserModel } from "../../../../@types/user-model";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
// import { WalletInfo } from "../../../../@types/wallet";
// import { useNavigate } from "react-router-dom";
import { RaffleTicketModel } from "../../../../@types/raffle";
import dayjs from "dayjs";
import { raffleTicketsList } from "../../../../selectors/raffleSelector";
import { Button, Spin } from "antd";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { staffSelectorData } from "../../../../selectors/staffSelector";
import InfoIcon from "@mui/icons-material/InfoOutlined";

const RaffleTickets = () => {
  const dispatch: AppDispatch = useDispatch();
  // const navigate = useNavigate();
  const raffleTicketsData: RaffleTicketModel[] | null =
    useSelector(raffleTicketsList);
  const [sortDirection, setSortDirection] = useState("descending");

  const isLoading = useSelector(usersSelectorLoadingState);
  const search = useSelector(searchQuery);
  const [selectedPartner, setSelectedPartner] = useState<string>("");
  const roles = useSelector(rolesSelectorList);
  const currentUser = useSelector(currentUserInfomation);
  const partners = useSelector(staffSelectorData);
  const theme = useTheme();

  const columns: CustomColumn<RaffleTicketModel>[] = useMemo(
    () => [
      { id: "raffleID", label: "Raffle ID" },
      { id: "channelID", label: "Raffle Type" },
      { id: "userPhoneNumber", label: "User" },
      {
        id: "raffleDate",
        label: "Date",
        format: (value: Date) => dayjs(value).format("YYYY-MM-DD"),
      },
    ],
    [],
  );

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser && roles) {
      const userRole = roles.find((role) => role.roleID === currentUser.roleID);
      if (userRole?.roleName === "ReadOnlyPartner") {
        setSelectedPartner(currentUser.staffID); // Set the current user as the selected partner if they are a ReadOnlyPartner
      } else {
        setSelectedPartner("All"); // Set to 'All' if the current user is not a ReadOnlyPartner
      }
    }
  }, [currentUser, roles]);

  const filteredTickets = useMemo(() => {
    let tickets = [...(raffleTicketsData || [])]; // Create a shallow copy of the array

    if (selectedPartner !== "All") {
      const partner = partners.find((p) => p.staffID === selectedPartner);
      tickets = tickets.filter((t) => t.businessID === partner?.businessID);
    }

    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      tickets = tickets.filter(
        (raffle) =>
          raffle.channelID.toLowerCase().includes(lowerCaseSearch) ||
          raffle.userPhoneNumber.toLowerCase().includes(lowerCaseSearch) ||
          raffle.raffleID.toString().toLowerCase().includes(lowerCaseSearch),
      );
    }

    return tickets.sort((a, b) =>
      a.raffleDate < b.raffleDate
        ? sortDirection === "ascending"
          ? -1
          : 1
        : sortDirection === "ascending"
          ? 1
          : -1,
    );
  }, [raffleTicketsData, selectedPartner, partners, search, sortDirection]);

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "ascending" ? "descending" : "ascending",
    );
  };
  const filteredPartners = useMemo(() => {
    // Find the roleID for 'ReadOnlyPartner' from the roles list
    const readOnlyPartnerRoleID = roles.find(
      (role) => role.roleName === "ReadOnlyPartner",
    )?.roleID;
    // Filter partners who have the 'ReadOnlyPartner' roleID
    return partners.filter(
      (partner) => partner.roleID === readOnlyPartnerRoleID,
    );
  }, [partners, roles]);

  const isAdmin = useMemo(() => {
    const adminRoles = ["Super Admin", "Admin"]; // Adjust based on your role names
    const currentUserRole =
      roles.find((role) => role.roleID === currentUser?.roleID)?.roleName || "";
    return adminRoles.includes(currentUserRole);
  }, [currentUser, roles]);

  const handlePartnerChange = (event: SelectChangeEvent<string>) => {
    setSelectedPartner(event.target.value);
  };

  if (
    isLoading ||
    !roles ||
    !currentUser ||
    !selectedPartner ||
    !raffleTicketsData
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Raffle Tickets
            </Typography>
            <Button onClick={toggleSortDirection}>Toggle Date Sort</Button>

            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              {filteredTickets.length}
            </Typography>
          </Box>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent={"flex-end"}
            sx={{ p: 2 }}
          >
            {isAdmin && (
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="partner-select-label">
                    Select Partner
                  </InputLabel>
                  <Select
                    labelId="partner-select-label"
                    id="partner-select"
                    value={selectedPartner}
                    onChange={handlePartnerChange}
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {filteredPartners.map((partner) => (
                      <MenuItem key={partner.staffID} value={partner.staffID}>
                        {partner.firstName} {partner.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {filteredTickets.length > 0 ? (
              <ReusableTable
                data={filteredTickets}
                columns={columns}
                paginationOptions={{
                  rowsPerPage: 20,
                  rowsPerPageOptions: [10, 20, 30],
                  showPagination: true,
                }}
                enableRowClick={true}
                // onRowClick={(item) => handleUserClick(item)}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 200,
                  mt: 4,
                }}
              >
                <InfoIcon color="action" sx={{ fontSize: 60 }} />
                <Typography variant="subtitle1" color="textSecondary">
                  No tickets available.
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RaffleTickets;
