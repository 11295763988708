// products-slice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProductsState,
  Products,
  UserProductHistory,
  AirtimeResponse,
  VtuPurchaseAggregates,
} from "../../@types/products-model";
import {
  createProducts,
  getProducts,
  updateProducts,
  deleteProducts,
  fetchAllUserProductsHistory,
  updateProductWon,
  fetchAllVtuPurchases,
  getVtuAggregatedAmounts,
} from "../thunks/product-thunk";

const initialProductsState: ProductsState = {
  data: [],
  productsHistory: [],
  vtuPurchases: [],
  vtuAggregateAmount: null,
  status: "idle",
  loading: false,
  message: null,
  error: null,
};

const productsSlice = createSlice({
  name: "products",
  initialState: initialProductsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(createProducts.fulfilled, (state) => {
        state.loading = false;
        state.message = "Product created successfully";
      })
      .addCase(createProducts.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        getProducts.fulfilled,
        (state, action: PayloadAction<Products>) => {
          state.data = action.payload.products;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(updateProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(updateProducts.fulfilled, (state) => {
        state.loading = false;
        state.message = "Product updated successfully";
        state.status = "succeeded";
      })
      .addCase(updateProducts.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });

    builder
      .addCase(deleteProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(deleteProducts.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
        state.message = "Product deleted successfully";
      })
      .addCase(deleteProducts.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(fetchAllUserProductsHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = "loading";
      })
      .addCase(
        fetchAllUserProductsHistory.fulfilled,
        (state, action: PayloadAction<UserProductHistory[]>) => {
          state.productsHistory = action.payload;
          state.loading = false;
          state.status = "succeeded";
        },
      )
      .addCase(
        fetchAllUserProductsHistory.rejected,
        (state, action: PayloadAction<unknown>) => {
          state.loading = false;
          state.error = action.payload as string;
          state.status = "failed";
        },
      );

    builder
      .addCase(updateProductWon.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
      })
      .addCase(updateProductWon.fulfilled, (state) => {
        state.status = "succeeded";
        state.message = null;
        state.loading = false;
      })
      .addCase(updateProductWon.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.message = null;
        state.loading = false;
      });
    builder
      .addCase(fetchAllVtuPurchases.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        fetchAllVtuPurchases.fulfilled,
        (state, action: PayloadAction<AirtimeResponse[]>) => {
          state.vtuPurchases = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(fetchAllVtuPurchases.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.status = "failed";
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || null;
        }
      });
    builder
      .addCase(getVtuAggregatedAmounts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.status = "loading";
      })
      .addCase(
        getVtuAggregatedAmounts.fulfilled,
        (state, action: PayloadAction<VtuPurchaseAggregates>) => {
          state.vtuAggregateAmount = action.payload;
          state.loading = false;
          state.message = null;
          state.status = "succeeded";
        },
      )
      .addCase(getVtuAggregatedAmounts.rejected, (state, action) => {
        state.loading = false;
        state.message = null;
        state.status = "failed";
        state.error = action.error.message || null;
      });
  },
});

export default productsSlice.reducer;
