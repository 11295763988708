import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { usersSelectorLoadingState } from "../../../../selectors/userSelector";
// import { UserModel } from "../../../../@types/user-model";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
// import { WalletInfo } from "../../../../@types/wallet";
import { RaffleWinnerCashOutPaymentModel } from "../../../../@types/raffle";
import dayjs from "dayjs";
import { payoutTransaction } from "../../../../selectors/raffleSelector";
import { Button } from "antd";
import { formatCurrency } from "../../../../utils";

const RafflePayoutListPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const payoutsTxtData: RaffleWinnerCashOutPaymentModel[] | null =
    useSelector(payoutTransaction);

  const [sortDirection, setSortDirection] = useState("descending");

  const isLoading = useSelector(usersSelectorLoadingState);
  const search = useSelector(searchQuery);

  const theme = useTheme();

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const columns: CustomColumn<RaffleWinnerCashOutPaymentModel>[] = useMemo(
    () => [
      {
        id: "tx_ref",
        label: "Transaction ID",
      },
      {
        id: "raffleID",
        label: "Raffle ID",
      },
      {
        id: "winnerID",
        label: "Winner ID",
      },
      {
        id: "phoneNumber",
        label: "User",
      },
      {
        id: "amount",
        label: "Txt Price (NGN)",
        format: (value) => formatCurrency(value),
      },
      {
        id: "status",
        label: "Status",
        format: (value: boolean) => (
          <Typography
            style={{
              color: value ? "green" : "red", // Green for 'Paid', red for 'Pending'
              fontWeight: "bold",
            }}
          >
            {value ? "Paid" : "Pending"}
          </Typography>
        ),
      },
      {
        id: "created_at",
        label: "Date",
        format: (value: string) => dayjs(value).format("YYYY-MM-DD"), // assuming the string is in a recognizable date format
      },
    ],
    [],
  );

  const sortedAndFilteredWallets = useMemo(() => {
    let sortableItems = payoutsTxtData ? [...payoutsTxtData] : [];
    if (search) {
      const searchLower = search.toLowerCase(); // Convert search term to lowercase once
      sortableItems = sortableItems.filter(
        (txf) =>
          txf.phoneNumber.toLowerCase().includes(searchLower) ||
          txf.tx_ref.toLowerCase().includes(searchLower) ||
          txf.raffleID.toLowerCase().includes(searchLower) ||
          txf.amount.toString().toLowerCase().includes(searchLower) ||
          txf.status.toString().toLowerCase().includes(searchLower),
      );
    }

    // Sorting logic
    sortableItems.sort((a, b) => {
      if (a.created_at < b.created_at) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (a.created_at > b.created_at) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return sortableItems;
  }, [payoutsTxtData, search, sortDirection]);

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "ascending" ? "descending" : "ascending",
    );
  };

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Payout Transaction History
            </Typography>
            <Button onClick={toggleSortDirection}>Toggle Date Sort</Button>

            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              {sortedAndFilteredWallets.length}
            </Typography>
          </Box>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={sortedAndFilteredWallets}
              columns={columns}
              paginationOptions={{
                rowsPerPage: 20,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
              enableRowClick={true}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RafflePayoutListPage;
