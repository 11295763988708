import React, { useState } from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { raffleLoadingState } from "../../../../selectors/raffleSelector";
import { Spin } from "antd";
import logging from "../../../../logging";
import { AppDispatch } from "../../../../store";
import { SendMessageRequest } from "../../../../@types/raffle";
import { sendUsersMessages } from "../../../../store/thunks/raffle-thunk";

const initialValues: SendMessageRequest = {
  category: "",
  message: "",
};

const validationSchema = Yup.object({
  category: Yup.string().required("Category is required"),
  message: Yup.string().required("Message is required"),
});

const SendMessageForm: React.FC = () => {
  const isLoading = useSelector(raffleLoadingState);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const handleFormSubmit = async (
    values: SendMessageRequest,
    actions: FormikHelpers<SendMessageRequest>,
  ) => {
    const payload: SendMessageRequest = {
      message: values.message,
      category: values.category,
    };
    try {
      const resultAction = await dispatch(sendUsersMessages(payload));
      if (sendUsersMessages.fulfilled.match(resultAction)) {
        logging.info("winner data updated successful!");
        setOpenSnackbar(true);
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
      actions.setSubmitting(false);
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent<any, Event> | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        mb={3}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Send Message to Raffle Participants
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ marginBottom: 2 }}></Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Grid item xs={12} sm={6} mb={6}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Field
                  as={TextField}
                  name="category"
                  label="Category"
                  select
                  fullWidth
                  variant="outlined"
                  helperText={touched.category && errors.category}
                  error={touched.category && Boolean(errors.category)}
                  margin="normal"
                >
                  <MenuItem value="1">1 (Once)</MenuItem>
                  <MenuItem value="2-3">2-3 (Twice to Thrice)</MenuItem>
                  <MenuItem value="4-5">4-5 (Four to Five Times)</MenuItem>
                  <MenuItem value="6-10">6-10 (Six to Ten Times)</MenuItem>
                  <MenuItem value="11-20">
                    11-20 (Eleven to Twenty Times)
                  </MenuItem>
                  <MenuItem value="21-50">
                    21-50 (Twenty-One to Fifty Times)
                  </MenuItem>
                  <MenuItem value="51+">51+ (More than Fifty Times)</MenuItem>
                </Field>
                <Field
                  as={TextField}
                  name="message"
                  label="Custom Message"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  helperText={touched.message && errors.message}
                  error={touched.message && Boolean(errors.message)}
                  margin="normal"
                />
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                  >
                    Send Message
                  </Button>
                </Box>
              </Paper>
            </Grid>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity="success"
                sx={{ width: "100%" }}
              >
                Message sent successfully!
              </Alert>
            </Snackbar>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SendMessageForm;
