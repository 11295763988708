import { AxiosResponse } from "axios";
import ApiService from "./api-service";
import {
  MonthlyCommunicationCost,
  UpdateMonthlyCostRequest,
} from "../@types/ussd-sms";

class UssdSmsService extends ApiService {
  async createNewUssdSmsCost(payload: MonthlyCommunicationCost): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/ussd-sms/create",
        payload,
      );
      await this.invalidateCacheByKey("get/ussd-sms/get-all-costs");
      return response.data;
    } catch (error) {
      console.error("Error create new ussd sms cost:", error);
      throw error;
    }
  }

  async fetchAllUssdSmsCost(): Promise<MonthlyCommunicationCost[]> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(
        `/ussd-sms/get-all-costs`,
      );
      return response.data;
    } catch (error) {
      console.error(
        "Failed to fetch all ussd sms cost for a business ID:",
        error,
      );
      throw error;
    }
  }

  async updateExistingUssdSmsCost(
    payload: UpdateMonthlyCostRequest,
  ): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/ussd-sms/update",
        payload,
      );
      await this.invalidateCacheByKey("get/ussd-sms/get-all-costs");
      return response.data;
    } catch (error) {
      console.error("Error update existing ussd sms cost:", error);
      throw error;
    }
  }
}

const ussdSmsService = new UssdSmsService();
export default ussdSmsService;
