import { CircularProgress, Typography, Box } from "@mui/material";
import React from "react";

const FullPageLoader: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh" // Full viewport height
      flexDirection="column"
    >
      <CircularProgress />{" "}
      {/* Material-UI CircularProgress component for a spinner */}
      <Typography variant="h6" sx={{ mt: 2 }}>
        Loading channels... Please wait.
      </Typography>
    </Box>
  );
};

export default FullPageLoader;
